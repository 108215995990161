import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import BuildModalHeaderIcon from "../images/icons/BuildModalHeaderIcon.svg"
import downloadIcon from "../images/icons/downloadIcon.svg"
import arrow from "../images/icons/arrow.svg"
import greentickIcon from "../images/icons/greentickIcon.svg"
import redcrossIcon from "../images/icons/redcrossIcon.svg"
import yellowerrorIcon from "../images/icons/yellowerrorIcon.svg"
import filtericon from "../images/icons/filtericon.svg"

function BuildModal({ show, handleClose }) { 
  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="collab-modal">
        <Modal.Body>
        <div className="head-section text-center">
            <img src={BuildModalHeaderIcon} alt="Header Icon" />
            <div className="text-data-section">
              <h2>Build</h2>
              <p>
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
          </div>
          <div className="container">
          <div class="build-table-container px-2">
          <div class="input-container">
  <div class="input-wrapper">
    <span class="input-icon">
        <img src={filtericon} alt="filtericon" />
    </span>
    <input type="text" placeholder="Filter students or classes" class="filter-input" />
  </div>
</div>
<div className="table-wrapper ">
<table class="build-table">
              <thead>
                <tr>
                  <th class="start">
                    <div className="build-table-thead-f-comp">
                    <span className="th-text">
                         Status & ID</span>
                         <img src={arrow} alt="down arrow" />
                         </div>
                  </th>
                  <th class="start">
                    <span className="th-text"> Amount</span>
                  </th>
                  <th class="start">
                    <span className="th-text"> Status</span>
                  </th>
                  <th class="end">
                    <span className="th-text">Recipt</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="first-td">
                    <img src={greentickIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="success-text">Success</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <img src={greentickIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="failed-text">Failed</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <img src={redcrossIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="success-text">Success</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <img src={yellowerrorIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="failed-text">Failed</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>

                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <img src={greentickIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="failed-text">Failed</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>

                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <img src={greentickIcon} alt="Avatar" class="avatar" />
                    <span className="name-column">3514464148</span>
                    </div>
                  </td>
                  
                  <td><span className="dollar-text">$299</span></td>
                  <td>
                    <span className="failed-text">Failed</span>
                  </td>
                  <td>
                    <img src={downloadIcon} alt="downloadIcon" className="downloadIcon"/>
                  </td>

                </tr>
              </tbody>
            </table>
</div>
          
          </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuildModal;
