import Dropdown from "react-bootstrap/Dropdown";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import docs from "../images/icons/support-icon.svg";
import policy from "../images/icons/legal-icon.svg";
import statusIcon from "../images/icons/status-icon.svg"
import logo from "../images/icons/logo.svg"
import apps from "../images/icons/apps.svg"
import bars from "../images/icons/bar.svg"
import darkMode from "../images/icons/dark-mode.svg"
import lightMode from "../images/icons/dark-mode.svg"
import newsRoom from "../images/icons/newsroom.svg"
import BrandDropdown from "./BrandDropdown";
import copy from "../images/icons/copy-icon.svg";
import brand from "../images/icons/brand-icon.svg";

export default function SecondaryHeader() {
  const [showMenu, setShowMenu] = useState(false);
  const [state, setState] = useState("");

  const showMenuHandler = () => {
    setShowMenu(true);
  };
  const closeMenuHandler = () => {
    setShowMenu(false);
  };
  const handleTheme = () => {
    if (state === "dark") {
      setState("light");
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.add("dark-theme");
      setState("dark");
      localStorage.setItem("theme", "dark");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.add("dark-theme");
      setState("dark");
    }
  }, []);

  return (
    <header className="header">
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-md-2 col-2 align-self-center">
            <div className="logo-wrapper">
              {/* <Link to="/" className="logo">
                <img src={logo} alt="logo" />{" "}
              </Link> */}
              <BrandDropdown />
              <a to="#" className="hamburger">
                <img
                  src={require("../images/icons/hamburger-icon.svg")}
                  alt="hamburgerIcon"
                  onClick={showMenuHandler}
                />
              </a>
            </div>
          </div>
          <div className="col-md-10 col-10 align-self-center">
            <div className={`menu-wrapper ${showMenu ? "active" : ""}`}>
              <RxCross2
                className="menu-close-icon"
                onClick={closeMenuHandler}
              />
              <ul className="menu">
                <li className="bordered">
                  {/* <img src={apps} alt="apps" /> */}
                  <div className="header-dropdown">
                    <div className="brand-dropdown-wrapper">
                      <Dropdown className="brand-dropdown">
                        <Dropdown.Toggle className="p-0"> 
                        <img src={apps} alt="apps" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="logo-dropdown">
                          <Dropdown.Item href="https://support.ilmiya.com/">
                            <div className="single-item">
                              <div className="icon">  
                                <img src={docs} alt="contract icon" />
                              </div>
                              <a>
                                Support
                              </a>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item href="https://blog.ilmiya.com/">
                            <div className="single-item">
                              <div className="icon">
                                <img src={newsRoom} alt="contract icon" />
                              </div>
                              <a>
                                Newsroom
                              </a>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item href="/legal">
                            <div className="single-item">
                              <div className="icon">
                                <img src={policy} alt="contract icon" />
                              </div>
                              <a>
                                Legal Center
                              </a>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item href="/contact-us">
                            <div className="single-item">
                              <div className="icon">
                              <img src={statusIcon} alt="contract icon" className="no-filter" />
                              </div>
                              <a>
                          Contact Us
                        </a>
                            </div>
                          </Dropdown.Item>

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  {/* <div className="header-modal">
                    <div className="modal-title">
                      <h4>Resources</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to="https://support.ilmiya.com/" target="_blank">
                          <div className="icon-wrapper">
                            <img src={docs} alt="contract icon" />
                          </div>
                          Support
                        </Link>
                      </li>
                      <li>
                        <Link to="https://blog.ilmiya.com/" target="_blank">
                          <div className="icon-wrapper">
                            <img src={newsRoom} alt="contract icon" />
                          </div>
                          Newsroom
                        </Link>
                      </li>
                      <li>
                        <Link to="/legal">
                          <div className="icon-wrapper">
                            <img src={policy} alt="contract icon" />
                          </div>
                          Legal Center
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <div className="icon-wrapper">
                            <img src={statusIcon} alt="contract icon" className="no-filter" />
                          </div>
                          System Status
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </li>

                <li className="bordered">
                  <img
                    onClick={handleTheme}
                    src={darkMode}
                    alt="dark"
                    className='light-theme-img'
                  />
                  <img
                    onClick={handleTheme}
                    src={lightMode}
                    alt="dark"
                    className='dark-theme-img filter-none'
                  />
                </li>
                <li >
                  <img src={bars} alt="bar" />
                </li>
                <li>
                  <Link to="/signup" className="btn-style text">
                    Login
                  </Link>
                </li>


              </ul>
              <div className="request-demo-btn">
                <Link
                  to="/contact-us"
                  className="btn-style text"
                  target="_blank"
                >
                  Request demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container large position-relative d-none d-none mobile-view">
        <div className="row">
          <div className="col-md-12 align-self-center">
            <div className="logo-wrapper">
              <img
                src={logo}
                alt="logo"
                className="logo"
              />
              <RxHamburgerMenu
                className="hamburger"
                onClick={showMenuHandler}
              />
              <div className={`left-menu ${showMenu ? "d-block" : "d-none"}`}>
                <ul className="menu">
                  <li>
                    <a to="#">Legal</a>
                  </li>
                  <li>
                    <a to="#">View demo</a>
                  </li>
                  <li>
                    <a to="#" className="btn-style">
                      Start free trial
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
