import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";

const SubProcessor = () => {
  return (
    <>
          <div className="legal-details-page-wrappper">
                    <SecondaryHeader />
                    {/* <LegalTopbar /> */}
                    <div className="page-breadcrumb">
                        <strong>
                            <Link to={"/legal"}> Legal Center</Link>
                            / Privacy Policy
                        </strong>
                    </div>
                    <div className="page-content-wrapper">
                        {/* <LegalSidebar /> */}
                        <div className="page-content">
                            <div className="container">
                                <div className="page-content-section">
                                    <div className="content-title">
                                        {/* <div className="icon">
                    <img src={ilmiyaIcon} alt="icon" />
                </div> */}
                                        <span>Ilmiya Sub-Processors List </span>
                                    </div>
<div className='content-wrapper'>
    <div className='description'>
        <p>In order to facilitate the provision of its Services, Ilmiya engages third-party service providers, known as Sub-Processors, along with affiliates, to assist in handling data processing operations on behalf of Ilmiya’s Business Clients, as outlined in our Ilmiya Services Agreement.</p>
    </div>

    <div className='description'>
        <h1>Definition of a Sub-Processor:</h1>
        <p>When Ilmiya contracts with external service providers to process personal data on behalf of its Business Clients, those entities are identified as sub-processors under the General Data Protection Regulation (“GDPR”) and other relevant international data privacy frameworks. Sub-processors are third parties that may access or handle personal data, which Ilmiya processes for and on behalf of its Business Clients.</p>
        <p>This document outlines the categories of service providers, sub-processors, and affiliates we engage with, their respective locations, and a description of the services they perform.</p>
    </div>

    <div className='description'>
        <h1>Due Diligence Protocol</h1>
        <p>Prior to entering into agreements with any third-party service provider, including sub-processors, Ilmiya conducts a rigorous due diligence process, which includes a thorough evaluation of the vendor's security practices. These service providers are bound by contractual obligations designed to ensure that any processing of personal data is done solely to support Ilmiya in delivering its services, and always in compliance with our commitments to Business Clients and applicable data protection laws.</p>
    </div>
    <div className='description'>
        <h1>List of Service Providers:</h1>
        <p> The following is a summary of key external partners that Ilmiya collaborates with across our suite of products and services. Additionally, certain service providers work with Ilmiya in connection with specific products or services offered by the company.</p>
    </div>

<div className='description'>
    <h1>Amendments to this Page</h1>
    <p>Given the dynamic nature of our global operations and the evolving needs of our Business Clients, the service providers we engage with may change from time to time. For instance, we may retire or consolidate certain service providers to streamline our operations, or conversely, introduce new providers when doing so enhances our ability to deliver Services effectively.</p>
    <p>This page will be updated periodically to reflect any additions or removals of service providers, sub-processors, or affiliates. Business Clients have the option to subscribe to email notifications regarding updates to the list of Sub-processors published on this page.</p>
</div>
<div className='description'>
    <h1>Right to Object</h1>
    <p>Pursuant to the terms outlined in our Data Processing Agreement (DPA), a Business Client may raise a reasonable objection in writing to the processing of its personal data by any newly appointed Sub-processor within thirty (30) days from the date of the update on this page. Should no objection be lodged within that time frame, the Business Client will be deemed to have accepted the appointment of the new Sub-processor. If you are a Business Client seeking additional information about our DPA, please contact us.</p>
    <p>For further details on Ilmiya's privacy practices, we encourage you to review our Privacy Policy. Should you have any inquiries related to the content of this page, please reach out to us directly.</p>
</div>


</div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SecondaryFooter /> 
    </>
  )
}

export default SubProcessor
