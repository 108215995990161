import React from 'react'
import HeaderComponent from '../components/HeaderComponent'
import MainSection from '../components/MainSection'
import AnalyticSection from '../components/AnalyticSection'
import AdminSection from '../components/AdminSection'
import EngageSection from '../components/EngageSection'
import Management from '../components/Management'
import FooterBottomBar from '../components/FooterBottomBar'
// import Navbar from '../components/Navbar'

export default function NewHome () {
    return (
        <>
        <HeaderComponent/>
        <MainSection/>
        <AnalyticSection/>
        <AdminSection/>
        <EngageSection/>
        <Management/>
        <FooterBottomBar/>

        
        </>
    )
}