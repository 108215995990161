import React from "react";
import browseSection from "../images/browse_activity.png";
import cardMember from "../images/card_membership.png";
import school from "../images/icons/school.png";
import fingerprint from "../images/finger.png";
import Vector from "../images/Vector 6967.png";
import macpro from "../images/MacBook Pro 19.png";
import leftImg from "../images/Vector 6965.png";
import rightImg from "../images/Vector 6966.png";
import lock from "../images/lock.png";
import browse from "../images/browse.png";
import elipse from "../images/Ellipse 4192.png";
import bottomImg from "../images/Group 1261156096.png";
import badge from "../images/Frame 1000003589.png";
import frame from '../images/frame2.png';
import vector2 from "../images/Vector 6969.png";
import layer from "../images/Layer 1.png";
import shortpicture from "../images/short Picture.png";
import line from "../images/Vector 7023.png";
import circle from "../images/Group 1261156438.png";




function AnalyticSection() {
  return (
    <div className="analytic-container">
      <div className="container">
      <p className="main-title main-heading">Analytics</p>
      <div>
        <h2 className="main-header">It all starts with the user</h2>
        <p className="main-desc">
          Learning Nexus is a dynamic platform designed to revolutionize the way{" "}
          <br />
          individuals engage. Nexus is a dynamic platform designed to
          revolutionize
          <br /> the way individuals engage.
        </p>
      </div>

      <div className=" row mt-5">
        {/* left section */}
        <div className="col-md-4 mt-4">
          <div className="left-multifactor-section">
          <div className="left-section">
            <div className="left-multifactor">
              <div className="analytic-img">
                <img src={school} alt="school" />
              </div>
              <div className="analytic-img">
                <img src={browseSection} alt="browseSection" />
              </div>
              <div className="analytic-img">
                <img src={cardMember} alt="cardMember" />
              </div>
            </div>
            <h2 className="left-multifactor-header analytic-header">
              Multifactor Authentication
            </h2>
            <p className=" analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
          </div>

          {/* left section-bottom */}
          <div className="left-section-bottom ">
            <div className="holistic-img">
              <img src={fingerprint} alt="fingerprint" />
            </div>
            <h2 className="left-multifactor-header analytic-header">Holistic Data</h2>
            <p className="analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
          </div>
          </div>
        </div>

        {/* center section */}
        <div className="col-md-4 mt-4">
          <div className="leader-board-section">
            <div className="leader-board-heading">
              <h2 className="analytic-header">Leaderboard</h2>
              <p className="analytic-desc">
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
            <img src={Vector} alt="Vector" className="vector-img" />

            <div className="leader-board-bottom">
              <img
                src={leftImg}
                alt="leftImg"
                className="leader-card-left-img leader-card-images"
              />
              <div className="leader-card">
                <img src={macpro} alt="macpro" className="leader-mac-img" />
                <p className="leader-card-text border-bottom">Device</p>
                <p className="leader-card-text border-bottom">Browse</p>
                <p className="leader-card-text border-bottom">Location</p>
                <p className="leader-card-text">Sign out device</p>
              </div>
              <img
                src={rightImg}
                alt="rightImg"
                className="leader-card-right-img leader-card-images"
              />
            </div>
          </div>
        </div>

        {/* right  section */}
        <div className="col-md-4 mt-4">
         <div className="right-report-section">
         <div className="report-card">
            <h2 className="analytic-header">Reports Cards</h2>
            <p className="analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
          </div>
          <div className="authentication-section">
            <div className="authentication-img">
              <img src={lock} alt="lock" className="authentication-lock" />
              <img
                src={bottomImg}
                alt="img"
                className="authentication-bottom-img"
              />
            </div>
            <h2 className="authentication-header analytic-header">
              Multifactor Authentication
            </h2>
            <p className=" analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
          </div>
         </div>
        </div>

        {/* bottom left section */}
        <div className="col-md-4 mt-4">
          <div className="bottom-left-authentication-section">
            <div className="bottom-left-authentication-heading">
            <h2 className="analytic-header">
              Multifactor Authentication
            </h2>
            <p className="analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
            </div>
            <div className="bottom-left-authentication-img">
              <div className="browse-img">
                <img src={browse} alt="browse" />
              </div>
              <img src={elipse} alt="elipse" className="elipse-img" />
             <div className="elipse-heading">
             <p className="elipse-title">Taha Bahaji</p>
             <p className="elipse-text">- English Teacher</p>
             </div>
            </div>
          </div>
        </div>

        {/* bottom center section */}
        <div className="col-md-4 mt-4">
          <div className="multifactor-bottom-section">
          <div className="bottom-center-multifactor-section">
            <div className="bottom-center-multifactor-img">
              <div className="multifactor-img multifactor-left-img">
                <img src={frame} alt="" />
                <img src={frame} alt=""  className="mulitfactor-frame"/>
              </div>

              <div className="multifactor-img multifactor-center-img">
                <img src={vector2} alt="vector" className="multifactor-vector"/>
                <img src={layer} alt="layer" className="layer-img" />
              </div>

              <div className="multifactor-img multifactor-right-img">
                <img src={frame} alt=""  />
                <img src={frame} alt="" className="multifactor-right-frame" />
              </div>
             
            </div>
            <div className="bottom-center-multifactor-heading">
            <h2 className="analytic-header">Multifactor Authentication</h2>
            <p className="analytic-desc">Learning Nexus is a dynamic platform designed to revolutionize the way engage.</p>
            </div>
          </div>

          <div className="left-section-bottom ">
            <div className="holistic-img">
              <img src={fingerprint} alt="fingerprint" />
            </div>
            <h2 className="left-multifactor-header analytic-header">Holistic Data</h2>
            <p className=" analytic-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way engage.
            </p>
          </div>
          </div>
        </div>

        {/* bottom right section */}

        <div className="col-md-4 mt-4">
          <div className="bottom-right-stream-section">
            <div className="stream-section-heading">
            <h2 className="analytic-header">Real Times Streams</h2>
            <p className="analytic-desc">Learning Nexus is a dynamic platform designed to revolutionize the way engage.</p>
            </div>

            <div className="stream-profile-section">
              <img src={badge} alt="badge" />
              <img src={shortpicture} alt="picture" />
              <p className="stream-profile-title">Bessie Cooper</p>
            </div>
            <div className="stream-bottom-img">
                <img src={line} alt="line" className="stream-line" />
                <img src={circle} alt="circle"  className="stream-cirlce"/>
                <div className="stream-cirlce2">
                <img src={circle} alt="circle" />
                <img src={circle} alt="circle" />
                <img src={circle} alt="circle" />
                </div>


              </div>

          </div>

        </div>




      </div>
    </div>
    </div>
  );
}

export default AnalyticSection;
