import React from "react";
import { Modal } from "react-bootstrap";

const CustomBuiltModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  const { modalHeaderImg, title, image, footer, textData } = data;

  return (
    <Modal show={isOpen} onHide={onClose} centered className="custom-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>

      <div className="image-wrapper">
                  <img
                    src={modalHeaderImg}
                    alt="Header img"
                    className="header-img"
                  />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 align-self-center">
              <div className="text-content-wrapper">
                <div className="text-content-inner">
                  <strong className="d-block">{title}</strong>
                  <span>Ilmiya’s AI provides real-time personalization, embedding intelligent insights directly into course materials. With secure, adaptive learning and intelligent data indexing, we deliver a deeply personalized,  educational experience.</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 align-self-center">
              <div className="side-img-parent">
              <div className="side-img-wrapper">
                <img src={image} alt="" className="align-self-center custom-built-side-image" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container">
          <div className="row">
            <div className="col-4 px-0 align-self-center">
              <div className="footer-content-wrapper">
                <div className="footer-icon-wrapper">
                  <img src={footer.footerimg1} alt="footer icon 1" />
                </div>
                <strong>{footer.footertext1}</strong>
              </div>
            </div>
            <div className="col-4 px-0 align-self-center">
              <div className="footer-content-wrapper">
                <div className="footer-icon-wrapper">
                  <img src={footer.footerimg2} alt="footer icon 2" />
                </div>
                <strong>{footer.footertext2}</strong>
              </div>
            </div>
            <div className="col-4 px-0 align-self-center">
              <div className="footer-content-wrapper">
                <div className="footer-icon-wrapper">
                  <img src={footer.footerimg3} alt="footer icon 3" />
                </div>
                <strong>{footer.footertext3}</strong>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomBuiltModal;
