import { Link } from 'react-router-dom'
import facebook from "../images/icons/facebook-icon.svg"
import xIcon from "../images/icons/x-icon.svg"
import linkedin from "../images/icons/linkedin-icon.svg"
import instagram from "../images/icons/instagram-icon.svg"
import { IoLogoGithub } from "react-icons/io";

export default function SecondaryFooter() {
  return (
    <>
      <div className="footer-bottom-bar">
        <div className="container large1">
          <div className="row">
            <div className="col-md-7 col-lg-8  ">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={require('../images/icons/systemicon.png')}
                    alt="logo"
                    className="logo"
                  />
                </Link>
                <ul className="bottom-menu">
                  <li>
                    <Link to="https://status.ilmiya.com/" target='_blank'>Status</Link>
                  </li>
                  <li>
                    <Link to="https://legal.ilmiya.com/p/KdXuVooqb8Z1BE/Terms-of-Service" target='_blank'>Terms</Link>
                  </li>
                  <li>
                    <Link to="https://legal.ilmiya.com/p/Q_NY3B88JWXzpS/Privacy-Policy" target='_blank'>Privacy</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-lg-4  ">
              <div className="social-items-wrapper">
                <ul className='bottom-menu'>
                  <li>
                    <Link to='https://www.facebook.com/myilmiya' target='_blank'>
                      <img src={facebook} alt='social-icon' />
                    </Link>
                  </li>
                  <li>
                    <Link to={"https://x.com/myilmiya"} target='_blank'>
                      <img src={xIcon} alt='social-icon' />
                    </Link>
                  </li>
                  <li>
                    <Link to='https://www.linkedin.com/company/ilmiya/' target='_blank'>
                      <img src={linkedin} alt='social-icon' />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/myilmiya/" target='_blank'>
                      <img src={instagram} alt='social-icon' />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://github.com/ilmiya/" target='_blank'>
                      <IoLogoGithub width={24} style={{ width: 24 }} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
