import FooterBottomBar from "../components/FooterBottomBar";
import HeaderComponent from "../components/HeaderComponent";
import aboutUsLogo from "../images/icons/aboutus-logo.png";
import Accordion from "react-bootstrap/Accordion";
import arrowDown from "../images/icons/arrow-down.svg";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";

export default function AboutUsPage() {
  return (
    <div className="aboutus-page-wrapper">
      <SecondaryHeader />
      <main>
        <div className="container">
          <div className="aboutus-page-content">
            <div className="aboutus-hero-section">
              <div className="row">
                <div className="col-8 align-self-center">
                  <div className="aboutus-title">
                    <h1>About Us</h1>
                    <p>Frequently Asked Questions</p>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <div className="logo-wrapper">
                  <img src={aboutUsLogo} alt="logo" />

                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="aboutus-content">
                <div className="accordions-wrapper">
                  <Accordion defaultActiveKey="0" className="aboutus-accordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="title">
                          <strong>What is Ilmiya, and who is it for?</strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Ilmiya is a versatile education platform designed for
                        schools, educators, and administrators. It offers tools
                        for learning management, engagement, and analytics,
                        making it easy to create, manage, and enhance
                        educational experiences.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="title">
                          <strong>How do I get started with Ilmiya?</strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        After signing up, you can follow our Getting Started
                        Guide available in the Ilmiya Basics section or contact
                        our support team. You’ll also have access to an
                        onboarding session to walk you through setup.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            What are the key features of Ilmiya’s LMS?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Ilmiya’s LMS is fully white-labeled, allowing
                        institutions to brand the platform as their own. Key
                        features include: Customizable branding, Flexible
                        configurations, Seamless integration with existing,
                        workflows, Support for multimedia content creation,
                        Real-time analytics and insights
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            {" "}
                            How do I add and manage students in Ilmiya?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        You can use the People module in Ilmiya Platform to add
                        students, manage classes, assign roles, and track
                        participation. Our detailed guide on the People module
                        walks you through each step.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            What are the benefits of using an this platform?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        A Learning Management System (LMS) is a software
                        platform designed to deliver, manage, and track
                        educational content and training programs. It serves as
                        a centralized hub for organizing learning materials,
                        administering courses, and assessing learners' progress.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            What is the difference between the People and Engage
                            modules?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The People module is focused on user and role
                        management, whereas the Engage module helps you monitor
                        courses, assignments, and communication with students
                        and parents.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            How can Ilmiya help me track student progress?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Ilmiya’s Analytics module provides real-time streams,
                        report cards, insights, and a leaderboard. These tools
                        offer a comprehensive view of student engagement,
                        academic progress, and overall performance.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            Can I customize the platform for my school’s needs?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, Ilmiya offers customization options such as custom
                        domains, labels, behaviors, and AI-driven agents to
                        tailor the platform to fit your institution's unique
                        needs.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            What kind of support does Ilmiya offer?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        Ilmiya offers ongoing support through our knowledge
                        base, onboarding resources, and our support team,
                        available via email or chat to help resolve any issues
                        or questions.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        <div className="title">
                          <strong>
                            How do I schedule follow-up or training sessions?
                          </strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        You can request additional training or follow-up
                        sessions with our team by contacting us through the
                        support portal or your dedicated customer success
                        representative.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                      <Accordion.Header>
                        <div className="title">
                          <strong>Is my data secure on Ilmiya?</strong>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        es, Ilmiya follows strict data protection and security
                        protocols, ensuring that all user data is safe,
                        compliant, and accessible only to authorized
                        individuals.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <SecondaryFooter />
      </footer>
    </div>
  );
}
