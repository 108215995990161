import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";

const StudentPrivacy = () => {
    return (
        <>
            <div className="legal-details-page-wrappper">
                <SecondaryHeader />
                {/* <LegalTopbar /> */}
                <div className="page-breadcrumb">
                    <strong>
                        <Link to={"/legal"}> Legal Center</Link>
                        / Privacy Policy
                    </strong>
                </div>
                <div className="page-content-wrapper">
                    {/* <LegalSidebar /> */}
                    <div className="page-content">
                        <div className="container">
                            <div className="page-content-section">
                                <div className="content-title">
                                    {/* <div className="icon">
                <img src={ilmiyaIcon} alt="icon" />
              </div> */}
                                    <span>Student Privacy Pledge</span>
                                </div>
                                <div className='content-wrapper'>
                                    <div className='description'>
                                        <p>K-12 School Service Providers ("Providers") are entrusted by educators, families, and schools to support educational needs and operations. As Providers, Ilmiya, Inc. ("Ilmiya") acknowledges and accepts the responsibility to both support the appropriate use of student information and to safeguard student privacy and information security in compliance with applicable laws and industry best practices.</p>
                                        <p>Our role is to assist schools—including teachers, students, and parents—in managing student data, supporting school operations, enhancing instructional and learning opportunities, and developing products and services intended for educational purposes. It is critical that we, as Providers, maintain the trust of educational institutions by implementing stringent safeguards for student information and ensuring transparent communication with parents and schools regarding how this information is utilized and protected. Student Personally Identifiable Information ("PII") is shared with us by students, parents, and schools for educational purposes; we are committed to ensuring that it is used only to serve the best interests of students</p>
                                        <p>This Pledge outlines our commitment to responsible data stewardship, usage, and protection of Student PII, in compliance with all applicable laws.</p>
                                    </div>

                                    <div className='description'>
                                        <h1>Our Commitments:</h1>
                                        <ol>
                                            <li>
                                                <strong>Collection and Use of Student PII</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not collect, maintain, use, or share Student PII beyond what is necessary for authorized educational purposes or as specifically authorized by a student or their parent/guardian.</p>
                                                    </li>
                                                    <li>
                                                        <p>We will use Student PII only for the purpose of providing educational services as directed by the educational institution or as otherwise authorized by the parent/student.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Prohibition on Sale of Student PII</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not sell Student PII to third parties for any purpose.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Limitation on Advertising</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not use or disclose Student PII collected through educational services for behavioral targeting of advertisements to students.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Restrictions on Student Profiling</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not build personal profiles of students, except as required for authorized educational purposes or as explicitly authorized by the student or their parent/guardian.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Policy Changes</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not make material changes to our privacy policies regarding the use of Student PII without providing prior notice to the school, institution, parent, or student, and allowing them to opt out or make informed decisions based on the changes.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Data Retention and Deletion</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will not retain Student PII beyond the time required to fulfill the authorized educational purposes, unless explicitly authorized by the parent/student.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Transparency</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will provide clear, concise, and easily accessible descriptions in our contracts and privacy policies regarding the types of Student PII we collect and the purposes for which such information is used or shared.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Access and Correction</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will facilitate access to and correction of Student PII by the student or authorized parent/guardian, either by assisting the educational institution or directly when Student PII is collected with student/parent consent.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Data Security</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will implement a comprehensive security program that includes administrative, technological, and physical safeguards appropriate to the sensitivity of the information, protecting against unauthorized access, use, disclosure, or destruction of Student PII.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Support for Educational Institutions and Families</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will provide resources to help schools, teachers, and families safeguard the privacy and security of Student PII while using our services.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Vendor Accountability</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will ensure that third-party vendors with whom we share Student PII adhere to the same privacy and security commitments.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Successor Entities</strong>
                                                <ul>
                                                    <li>
                                                        <p>In the event of a merger, acquisition, or other corporate change, we will ensure that any successor entity is legally bound to comply with these privacy commitments for all previously collected Student PII.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Ongoing Development and Compliance</strong>
                                                <ul>
                                                    <li>
                                                        <p>We will incorporate privacy and security principles in the development and improvement of our educational products, services, and tools, in compliance with all applicable legal and regulatory requirements.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </div>

                                    <div className='description'>
                                        <h1>Additional Considerations</h1>
                                        <p>Some Providers may be subject to additional legal obligations or contractual commitments, including those directing or authorizing additional uses of Student PII, other than those outlined above.</p>
                                        <p>This Pledge does not prohibit the use of Student PII for adaptive learning or personalized education purposes where such use is consistent with applicable laws and authorized by the school or parent/student.</p>
                                        <p>This Pledge is intended to apply to new contracts and policies going forward and will be integrated into existing contracts as updated over time.</p>
                                    </div>

                                    <div className='description'>
                                        <h1>Compliance with COPPA and Other Legal Requirements</h1>
                                        <p>Providers subject to the Children’s Online Privacy Protection Act (COPPA) are responsible for obtaining parental or school consent where appropriate. The Federal Trade Commission (FTC) has clarified that organizations must comply with COPPA requirements independently and should not shift this responsibility to schools in their terms of service. For more details, refer to the FTC’s guidelines on complying with COPPA ( <span>https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions-0</span> )</p>
                                        <p>For further resources, visit the Student Privacy Compass at: <span>https://studentprivacycompass.org/audiences/ed-tech/.</span></p>
                                    </div>
                                    <div className='description'>
                                        <h1>Effective Date</h1>
                                        <p>This Pledge is effective as of September 22, 2024. Signatories are bound by the terms of this Pledge upon opting into the updated terms. The Legacy Student Privacy Pledge will sunset in June 2021. After this date, the updated Student Privacy Pledge will be the only active Pledge in effect.</p>
                                    </div>
                                    <div className='description'>
                                        <h1>Definitions:</h1>
                                        <ul>
                                            <li>
                                                <p><strong>School Service Provider:</strong> Any entity providing an online or mobile application, online service, or website marketed for use by U.S. elementary and secondary educational institutions, and that collects, maintains, or uses Student PII.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p><strong>Educational/School Purposes:</strong> Services or functions typically conducted under the direction of educational institutions, including instruction, administration, and the development of educational products and services.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                              <p><strong>Signatory:</strong> A School Service Provider that signs and commits to the Student Privacy Pledge</p>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <p><strong>Student PII:</strong> Student personally identifiable information, as defined by California’s SOPIPA (Cal. Bus. & Prof. Code § 22584(i) (2020)).</p>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <p><strong>Parent:</strong> A student's legal guardian.</p>
                                            </li>
                                        </ul>
                                        <p>This Pledge serves as a promise by each signatory to adhere to these commitments. It does not constitute a comprehensive privacy policy nor serve as an official audit or endorsement by any governmental agency. Nonetheless, the FTC and state Attorneys General have the authority to enforce these commitments. Schools may also have contractual rights to enforce this Pledge through their agreements with Providers.</p>

                                    </div>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter />
        </>
    )
}

export default StudentPrivacy
