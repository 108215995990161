import "./App.css";
import AboutUsPage from "./assets/pages/AboutUs";
import ContactUsPage from "./assets/pages/ContactUs";
import HomePage from "./assets/pages/HomePage";
import "./assets/scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignupPage from "./assets/pages/SignupPage";
import LegalPage from "./assets/pages/LegalPage";
import LegalDetailPage from "./assets/pages/LegalDetail";
import NotFound from "./assets/pages/NotFound";
import ThankYouPage from "./assets/pages/ThankYou";
import HomepageNew from "./assets/pages/HomePageNew";
import RouterPage from "./assets/pages/Router";
import Usagepolicy from "./assets/pages/Usagepolicy";
import Dataprotection from "./assets/pages/Dataprotection";
import Privacypolicy from "./assets/pages/Privacypolicy";
import Termsofprivacy from "./assets/pages/Termsofprivacy";
import PrivacyDisclouser from "./assets/pages/PrivacyDisclouser";
import ParentalConsent from "./assets/pages/ParentalConsent";
import StudentPrivacy from "./assets/pages/StudentPrivacy";
import MarkUsage from "./assets/pages/MarkUsage";
import UsageGuidelines from "./assets/pages/UsageGuidelines";
import SubProcessor from "./assets/pages/SubProcessor";
import Brand from "./assets/pages/Brand";
import AboutNew from "./assets/pages/AboutNew";
import NewHome from "./assets/pages/NewHome";
import AboutDemoPageSideModals from "./assets/pages/AboutDemoPageSideModals";
function App() {
  return (
    
    <>
    <Router>
            <Routes>
                <Route path="/" element={<HomepageNew />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/legal" element={<LegalPage />} />
                <Route path="/legal-detail" element={<LegalDetailPage />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/thank-you" element={<ThankYouPage />}/>
                <Route path="/router" element={<RouterPage />}/>
                <Route path="/usage-policy" element={<Usagepolicy />}/>
                <Route path="/data-protection" element={<Dataprotection />}/>
                <Route path="/privacy-policy" element={<Privacypolicy />}/>
                <Route path="/terms-of-services" element={<Termsofprivacy />}/>
                <Route path="/disclouser" element={<PrivacyDisclouser />}/>
                <Route path="/consent" element={<ParentalConsent />}/>
                <Route path="/student-privacy" element={<StudentPrivacy />}/>
                <Route path="/mark-usage" element={<MarkUsage />}/>
                <Route path="/guidelines" element={<UsageGuidelines />}/>
                <Route path="/processor" element={<SubProcessor />}/>
                <Route path="/brand" element={<Brand />}/>
                <Route path="/about-new" element={<AboutNew />}/>
                <Route path="/new-home" element={<NewHome />}/>
                <Route path="/demoaboutpage" element={<AboutDemoPageSideModals />}/>
            </Routes>
        </Router>
      
    </>
  );
}

export default App;
