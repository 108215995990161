import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";

const MarkUsage = () => {
    return (
        <>
            <div className="legal-details-page-wrappper">
                <SecondaryHeader />
                {/* <LegalTopbar /> */}
                <div className="page-breadcrumb">
                    <strong>
                        <Link to={"/legal"}> Legal Center</Link>
                        / Privacy Policy
                    </strong>
                </div>
                <div className="page-content-wrapper">
                    {/* <LegalSidebar /> */}
                    <div className="page-content">
                        <div className="container">
                            <div className="page-content-section">
                                <div className="content-title">
                                    {/* <div className="icon">
                <img src={ilmiyaIcon} alt="icon" />
              </div> */}
                                    <span>Ilmiya's Mark Usage Terms </span>
                                </div>
                                <div className='content-wrapper'>
                                    <div className='description'>
                                        <p>We recognize that many of our Users, as well as our partners, vendors, and other third parties connected to us, may wish to reference their association with Ilmiya, often by using our name or logo. These <strong> Marks Usage Terms</strong>  (hereinafter referred to as the "Terms") are designed to clarify how Users may appropriately reference and use <strong>Ilmiya</strong>'s Marks. If you have any questions regarding these Terms or wish to use our Marks in a manner other than to indicate that you are an <strong>Ilmiya User</strong> (e.g., in marketing materials, promotional efforts, etc.), please reach out to us at <span> hi@ilmiya.com</span>.</p>
                                    </div>
                                    <div className='description'>
                                        <h1>License and Applicability</h1>
                                        <p>n some cases, specific uses of Ilmiya's Marks may require a formal license. Where such a license exists, the terms of that agreement shall govern. In other circumstances, separate agreements may dictate the use of <strong>Ilmiya</strong> 's Marks, and where those agreements apply, they will prevail. These <strong>Marks Usage Terms</strong> govern all other uses of <strong>Ilmiya</strong>'s Marks not expressly covered by another license or agreement.</p>
                                    </div>

                                    <div className='description'>
                                        <h1>General Principles</h1>
                                        <p>Before detailing specific permissible and impermissible uses, let us establish some foundational concepts:</p>
                                        <ol>
                                            <li>
                                                <strong>Definition of "Marks":</strong>
                                                <p>The term "Marks" refers to any names, logos, icons, design elements, trade dress, or other identifying symbols associated with Ilmiya, whether registered or unregistered, which serve to distinguish our goods and services. For instance, this includes:</p>
                                                <ul>
                                                    <li>
                                                        <p>The<strong>Ilmiya</strong> word mark;</p>
                                                    </li>
                                                    <li>
                                                        <p>The<strong>Ilmiya</strong> stylized logo;</p>
                                                    </li>
                                                    <li>
                                                        <p>Any other distinctive design or trademark associated with<strong>Ilmiya</strong></p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <p>The aforementioned list is illustrative and not exhaustive. These Marks are protected and registered in various jurisdictions, including the United States Patent and Trademark Office and other international trademark offices. Should you wish to use any of our Marks not explicitly covered by these Terms, please contact us at <span>hi@ilmiya.com.</span></p>
                                            </li>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Ownership and Goodwill:</strong>
                                                <p>By utilizing <strong>Ilmiya'</strong> s Marks in accordance with these Terms, you acknowledge that <strong>Ilmiya</strong> retains sole ownership of the Marks. You further agree not to challenge or otherwise interfere with our ownership rights, including the use, registration, or application to register any Mark. Any goodwill generated through the use of <strong>Ilmiya'</strong> s Marks inures exclusively to <strong>Ilmiya'</strong> s benefit.</p>
                                            </li>
                                        </ol>
                                        <ol>

                                            <ul>
                                                <li>
                                                    <p>The usage must conform strictly to these <strong>Marks Usage Terms</strong>.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                        <ol>
                                            <li>
                                                <strong>Limitations of Use:</strong>
                                                <p>Your permission to use <strong>Ilmiya'</strong>s Marks is subject to several limitations:</p>
                                            </li>
                                            <ul>
                                                <li>
                                                    <p>The usage must conform strictly to these <strong>Marks Usage Terms</strong>.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                        <ol>
                                            <ul>
                                                <li>
                                                    <p>The permission is non-exclusive and non-transferable. This means Ilmiya reserves the right to grant permission to others and does not permit the transfer of this authorization to third parties.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                        <ol>
                                            <ul>
                                                <li>
                                                    <p>Ilmiya reserves the right to amend these <strong>Marks Usage Terms</strong>at its sole discretion. You agree to update your usage to align with any revisions within a reasonable time after receiving notice of the changes.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                        <ol>

                                            <ul>
                                                <li>
                                                    <p> <strong>Ilmiya</strong> may review your use of its Marks and require adjustments to ensure compliance with these Terms.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                        <ol>

                                            <ul>
                                                <li>
                                                    <p> <strong>Ilmiya</strong>retains the absolute discretion to terminate your permission to use its Marks at any time. Upon receiving notice of such termination, you agree to immediately cease all use of the Marks.</p>
                                                </li>
                                            </ul>
                                        </ol>

                                    </div>

<div className='description'>
    <h1>Permissible Uses of Ilmiya’s Marks</h1>
    <strong>You may:</strong>
    <ul>
        <li>
            <p>Use <strong>Ilmiya'</strong>s Marks on websites or applications that are directly related to <strong>Ilmiya'</strong> services (e.g., indicating the use of <strong>Ilmiya</strong> Language Models or AI tools within your platform).</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Display<strong>Ilmiya'</strong>s Marks in accordance with any style guidelines (e.g., size, color, or placement) provided by <strong>Ilmiya</strong>. You agree to promptly update your use if those guidelines are modified.</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Use the <strong>Ilmiya</strong> word mark, without modification, to accurately reference Ilmiya or its services.</p>
        </li>
    </ul>

</div>

<div className='description'>
    <h1>Prohibited Uses of Ilmiya’s Marks</h1>
    <strong>You may not:</strong>
    <ul>
        <li>
            <p>Use Ilmiya's Marks in any way not expressly permitted by these Terms or without written approval.</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Modify or alter Ilmiya's Marks. This includes, but is not limited to, abbreviating, shortening, pluralizing, translating, or otherwise altering the Marks in any form.</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Misrepresent your relationship with Ilmiya, or use Ilmiya's Marks in a manner that implies endorsement, sponsorship, or affiliation beyond what is expressly permitted by these Terms.</p>
        </li>
    </ul>


    <ul>
        <li>
            <p>Use Ilmiya's Marks more prominently than your own brand or any other third-party brands.</p>
        </li>
    </ul>


    <ul>
        <li>
            <p>Use Ilmiya's Marks in a context unrelated to Ilmiya's goods or services, or in any manner that may cause confusion about your relationship with Ilmiya.</p>
        </li>
    </ul>


    <ul>
        <li>
            <p>Use Ilmiya's Marks on any tangible merchandise, promotional materials, or other physical items without explicit authorization.</p>
        </li>
    </ul>


    <ul>
        <li>
            <p>Add any elements or words in such proximity to Ilmiya's Marks as to create a new or distinct impression.</p>
        </li>
    </ul>


    <ul>
        <li>
            <p>Use or incorporate any of <strong>Ilmiya</strong>'s Marks into your own trademarks, service marks, trade dress, business names, domain names, or social media handles, nor use any branding likely to cause confusion with <strong>Ilmiya</strong>'s Marks.</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Use Ilmiya's Marks in any manner that disparages or reflects negatively on <strong>Ilmiya</strong>, its services, or its brand.</p>
        </li>
    </ul>

    <ul>
        <li>
            <p>Use any ™ or ® designations in connection with <strong>Ilmiya</strong>'s Marks unless specifically authorized. Different jurisdictions have differing rules, and we seek to maintain uniformity in these respects across regions.</p>
        </li>
    </ul>
</div>

<div className='description'>
    <h1>Final Considerations</h1>
    <p>Your adherence to these Marks Usage Terms is essential to maintaining the integrity of <strong>Ilmiya</strong>'s brand. Failure to comply may result in the immediate termination of your permission to use our Marks, and may necessitate legal action to protect our interests.</p>
    <p>If you wish to explore uses of <strong>Ilmiya</strong>'s Marks outside the scope of these Terms or have additional questions, please contact us at <span>hi@ilmiya.com.</span></p>
    <p>Thank you for your cooperation and for supporting the continued growth and success of <strong>Ilmiya</strong>.</p>
</div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter />

        </>
    )
}

export default MarkUsage
