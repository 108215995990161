import { Accordion } from "react-bootstrap";
import HeaderComponent from "../components/HeaderComponent";
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import checkGreen from "../images/check-green.svg";
export default function ThankYouPage() {
  return (
    <div className="thank-you-page-wrapper contact-us-page-new pt-0 pb-0">
      <SecondaryHeader />
      <div className="page-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <div className="contact-page-content">
                <div className="title">
                  <h1>
                    Ready to take your teaching{" "}
                    <span>to a whole new level?</span>
                  </h1>
                </div>
                <div className="content-section">
                  <p>
                    At Ilmiya, we provide everything you need to build, manage,
                    and scale impactful language learning programs—from content
                    creation and student engagement tools to secure, reliable
                    hosting. Let’s work together to expand your reach and
                    transform learning experiences worldwide!
                  </p>
                  <div className="contactus-accordions-wrapper">
                    <Accordion
                      defaultActiveKey="0"
                      className="contactus-accordion"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="title">
                            <strong>What is Ilmiya's Platform?</strong>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Ilmiya is an all-in-one platform designed to help
                          educators create, scale, and manage language learning
                          programs globally. With built-in tools for content
                          creation, student engagement, and performance
                          tracking, Ilmiya empowers teachers to expand their
                          reach and impact.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <div className="title">
                            <strong>
                              How easy is it to implement Ilmiya in my teaching
                              program?
                            </strong>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Ilmiya is designed to be user-friendly, with a
                          straightforward setup process and intuitive interface.
                          Whether you're tech-savvy or new to digital tools,
                          Ilmiya makes it easy to integrate into your teaching
                          workflow.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <div className="title">
                            <strong>
                              Will Ilmiya handle the hosting for my content and
                              resources?
                            </strong>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          Yes, Ilmiya provides secure hosting for all your
                          teaching materials, student data, and resources. Our
                          platform ensures that your content is accessible
                          globally with reliable uptime, so you can focus on
                          teaching without worrying about technical
                          infrastructure.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <div className="title">
                            <strong> Is any development work required?</strong>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          No development work is required to get started with
                          Ilmiya. Our platform is ready-to-use, with
                          customizable templates and features that allow you to
                          personalize your program without needing coding
                          skills.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="page-content">
                <div className="page-inner">
                  <div className="check-icon">
                    <img src={checkGreen} alt="check icon" />
                  </div>
                  <div className="content-section">
                    <div className="title">
                      <h1>
                        <span>
                          Thank You : <br />
                        </span>
                        We’ll be in touch soon
                      </h1>
                    </div>
                    <p>
                      At Ilmiya, we're dedicated to empowering educators by
                      providing a comprehensive platform to build, manage, and
                      scale language learning programs. With tools for content
                      creation, student engagement, and secure hosting, we help
                      you expand your reach and impact globally. We look forward
                      to connecting with you soon!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mobileview-accordions">
                <Accordion defaultActiveKey="0" className="contactus-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="title">
                        <strong>What is Ilmiya's Platform?</strong>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Ilmiya is an all-in-one platform designed to help
                      educators create, scale, and manage language learning
                      programs globally. With built-in tools for content
                      creation, student engagement, and performance tracking,
                      Ilmiya empowers teachers to expand their reach and impact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="title">
                        <strong>
                          How easy is it to implement Ilmiya in my teaching
                          program?
                        </strong>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Ilmiya is designed to be user-friendly, with a
                      straightforward setup process and intuitive interface.
                      Whether you're tech-savvy or new to digital tools, Ilmiya
                      makes it easy to integrate into your teaching workflow.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="title">
                        <strong>
                          Will Ilmiya handle the hosting for my content and
                          resources?
                        </strong>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, Ilmiya provides secure hosting for all your teaching
                      materials, student data, and resources. Our platform
                      ensures that your content is accessible globally with
                      reliable uptime, so you can focus on teaching without
                      worrying about technical infrastructure.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="title">
                        <strong> Is any development work required?</strong>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      No development work is required to get started with
                      Ilmiya. Our platform is ready-to-use, with customizable
                      templates and features that allow you to personalize your
                      program without needing coding skills.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </div>
  );
}
