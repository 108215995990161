import SecondaryHeader from "../components/SecondaryHeader";
import ilmiyaIcon from "../images/icons/ilmiya-icon.svg";
import iPad from "../images/iPad.png";
import LegalTopbar from "../components/LegalTopbar";
import { Link } from "react-router-dom";
import LegalSidebar from "../components/LegalDetailSidebar";
import SecondaryFooter from "../components/SecondaryFooter";

/*************  ✨ Codeium Command ⭐  *************/
/**
 * Renders the Privacy Policy page of the Ilmiya website.
 * This component includes various sections detailing the privacy
 * practices of Ilmiya, such as information collection, data use,
 * security measures, and user rights under different data protection
 * laws like GDPR, CalOPPA, and CCPA. It utilizes components for the
 * header and footer, and contains links to related legal pages.
 */
/******  68a59626-5fe4-4701-ad82-b2eb78d36391  *******/export default function Privacypolicy() {
    return (
        <>

            <div className="legal-details-page-wrappper">
                <SecondaryHeader />
                {/* <LegalTopbar /> */}
                <div className="page-breadcrumb">
                    <strong>
                        <Link to={"/legal"}> Legal Center</Link>
                        / Privacy Policy
                    </strong>
                </div>
                <div className="page-content-wrapper">
                    {/* <LegalSidebar /> */}
                    <div className="page-content">
                        <div className="container">
                            <div className="page-content-section">
                                <div className="content-title">
                                    {/* <div className="icon">
            <img src={ilmiyaIcon} alt="icon" />
          </div> */}
                                    <span>Privacy Policy </span>
                                </div>
                                <div className="content-wrapper">
                                    <div className='description'>
                                        <h1>1. Introduction</h1>
                                        <p>Welcome to Ilmiya</p>
                                        <p>Our Privacy Policy governs your visit to ilmiya.com and Ilmiya mobile applications, and explains how we collect, safeguard and disclose information that results from your use of our Service.</p>
                                        <p>We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our <span>Terms of Service</span></p>
                                        <p>Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“agreement”).</p>
                                    </div>
                                    <div className='description'>
                                        <h1></h1>
                                        <ul>
                                            <li>
                                                <p>SERVICE means the <span>ilmiya.com</span> website and Ilmiya mobile application operated by Ilmiya.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>PERSONAL DATA means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>USAGE DATA is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <parseInt>COOKIES are small files stored on your device (computer or mobile device).</parseInt>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>DATA CONTROLLER means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>DATA SUBJECT is any living individual who is the subject of Personal Data.</p>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <p>THE USER is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='description'>
                                    <h1>3. Information Collection and Use</h1>
                                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                                </div>

                                <div className='description'>
                                    <h1> Types of Data Collected</h1>
                                    <p>Personal Data</p>
                                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>

                                    <ul>
                                        <li>
                                            <p>Email address</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p>First name and last name</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p>Phone number</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p>Address, State, Province, ZIP/Postal code, City</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p>Cookies and Usage Data</p>
                                        </li>
                                    </ul>
                                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <span>support@ilmiya.com.</span></p>
                                    <p>Usage Data:</p>
                                    <ul>
                                        <li>
                                            <p>We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (“Usage Data”).</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
                                        </li>
                                    </ul>


                                    <p>Location Data</p>

                                    <ul>
                                        <li>
                                            <p>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
                                        </li>
                                    </ul>
                                    <p>Tracking Cookies Data</p>
                                    <ul>
                                        <li>
                                            <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                                        </li>
                                    </ul>
                                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
                                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                    <p>Examples of Cookies we use:</p>

                                    <ul>
                                        <li>
                                            <p>Session Cookies: We use Session Cookies to operate our Service.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Preference Cookies: We use Preference Cookies to remember your preferences and various settings.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Security Cookies: We use Security Cookies for security purposes.</p>
                                        </li>
                                    </ul>

                                </div>

                                <div className='description'>
                                    <h1>4. Use of Data</h1>
                                    <p> Ilmiya uses the collected data for various purposes:</p>
                                    <ul>
                                        <li>
                                            <p>To provide and maintain our Service;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To notify you about changes to our Service;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To allow you to participate in interactive features of our Service when you choose to do so;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To provide user support;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To gather analysis or valuable information so that we can improve our Service;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To monitor the usage of our Service;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To detect, prevent and address technical issues;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To fulfill any other purpose for which you provide it;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>In any other way we may describe when you provide the information;</p>
                                        </li>
                                    </ul>


                                    <ul>
                                        <li>
                                            <p>For any other purpose with your consent.</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className='description'>
                                    <h1>6. Retention of Data</h1>
                                    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                                    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
                                </div>

                                <div className='description'>
                                    <h1>7. Transfer of Data</h1>
                                    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                                    <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
                                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                                    <p>Ilmiya will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                                </div>

                                <div className='description'>
                                    <h1>8. Disclosure of Data</h1>
                                    <p>We may disclose personal information that we collect, or you provide:</p>
                                    <p>Disclosure for Law Enforcement.</p>
                                    <p>Imiya respects the human rights of our users. Ilmiya implements a Law Enforcement Request Policy which is designed to ensure that all law enforcement, governmental and regulatory requests are valid and made in accordance with applicable legal process. Ilmiya does not disclose data to law enforcement, regulatory or governmental bodies unless required by applicable law and challenges unlawful requests. If Ilmiya receives a demand for Your Controlled Data, Ilmiya will attempt to redirect the law enforcement agency or regulatory or government body to request such data directly from the relevant user. If compelled to disclose or provide access to data to law enforcement, regulatory or governmental bodies or agencies, Ilmiya will, unless legally prohibited from doing so, notify the relevant user and provide them with a copy of the demand to allow them to seek a protective order or other appropriate remedy, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation.</p>
                                    <p>Business Transaction.</p>
                                    <p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</p>
                                    <p>Other cases. We may disclose your information also:</p>
                                    <ul>
                                        <li>
                                            <p>To our subsidiaries and affiliates;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To contractors, service providers, and other third parties we use to support our business;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>To fulfill the purpose for which you provide it;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>For any other purpose disclosed by us when you provide the information;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>With your consent in any other cases;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our users, or others.</p>
                                        </li>
                                    </ul>

                                </div>

                                <div className='description'>
                                    <h1>9.  Security of Data</h1>
                                    <p>We work very hard to protect information about you against unauthorized access, use, alteration, or destruction, and take reasonable measures to do so. We monitor our Services for potential vulnerabilities and attacks. The Ilmiya security team is responsible for developing, implementing and maintaining an information security program that reflects the following:</p>


                                    <ul>
                                        <li>
                                            <p>Leverage security to facilitate confidentiality, integrity, and availability of data and assets.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Support secure infrastructure, platform, and feature development.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Manage security utilizing a risk based approach.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Leverage industry security and compliance frameworks where relevant and applicable.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Cloud and Software Providers</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Ilmiya leverages leading data center, cloud service, and software as a service providers to house our physical, cloud, and software infrastructure.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Our providers utilize an array of security equipment, techniques, and procedures designed to control, monitor, and record access to the facilities.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Ilmiya has implemented solutions designed to protect against and mitigate effects of DDoS attacks.</p>
                                        </li>
                                    </ul>
                                    <p>Encryption:</p>
                                    <ul>
                                        <li>
                                            <p>Ilmiya leverages SSL certificates to encrypt data in-transit between website end users, as well as implements encryption at rest.</p>
                                        </li>
                                    </ul>
                                    <p>Systems Access Control:</p>

                                    <ul>
                                        <li>
                                            <p>Access to Ilmiya systems is limited to appropriate personnel.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Ilmiya subscribes to the principle of least privilege.</p>
                                        </li>
                                    </ul>
                                    <p>Ilmiya access control policy applies to systems that Ilmiya manages and maintains. The Ilmiya access control policy addresses control processes that include, but are not limited to:</p>

                                    <ul>
                                        <li>
                                            <p>Account provisioning/decommissioning</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p></p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Authentication</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Privileged account management</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>User identification</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Access logging and monitoring</p>
                                        </li>
                                    </ul>

                                </div>

                                <div className='description'>'
                                    <h1>10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h1>
                                    <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. – See more at<span>https://eur-lex.europa.eu/eli/reg/2016/679/oj</span></p>
                                    <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data</p>
                                    <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <span>  support@ilmiya.com.</span></p>
                                    <p>In certain circumstances, you have the following data protection rights:</p>
                                    <ul>
                                        <li>
                                            <p>The right to access, update or to delete the information we have on you; </p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The right to object. You have the right to object to our processing of your Personal Data;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</p>
                                        </li>
                                    </ul>
                                    <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</p>
                                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

                                </div>

                                <div className='description'>
                                    <h1>11. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</h1>
                                    <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: <span> https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</span> </p>
                                    <p>ccording to CalOPPA we agree to the following:</p>
                                    <ul>
                                        <li>
                                            <p>Users can visit our site anonymously;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Users will be notified of any privacy policy changes on our Privacy Policy Page;</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Users are able to change their personal information by emailing us at <span>support@ilmiya.com.</span></p>
                                        </li>
                                    </ul>

                                    <p>Our Policy on “Do Not Track” Signals:</p>

                                    <ul>
                                        <li>
                                            <p>We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className='description'>
                                    <h1>12. Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</h1>
                                    <p>If  you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</p>

                                    <ul>
                                        <li>
                                            <p>What personal information we have about you. If you make this request, we will return to you:</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The categories of personal information we have collected about you.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The categories of sources from which we collect your personal information.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The business or commercial purpose for collecting or selling your personal information.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>
                                                The categories of third parties with whom we share personal information.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>The specific pieces of personal information we have collected about you.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</p>
                                        </li>
                                    </ul>

                                    <p>Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</p>
                                    <p>To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.</p>
                                    <p>We don't sell or rent your personal information to any third parties for any purpose. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</p>
                                    <p>Please note, if you ask us to delete your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</p>
                                    <p>To exercise your California data protection rights described above, please send your request(s) by one of the following means:</p>
                                    <p>By email: <span>support@ilmiya.com.</span></p>
                                    <p>Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.</p>

                                </div>
                                <div className='description'>
                                    <h1>13. Service Providers</h1>
                                    <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.</p>
                                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                                </div>

                                <div className='description'>
                                    <h1>14. Analytics</h1>
                                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                                </div>
                                <div className='description'>
                                    <h1>15. CI/CD tools</h1>
                                    <p>We may use third-party Service Providers to automate the development process of our Service.</p>
                                </div>

                                <div className='description'>
                                    <h1>16. Behavioral Remarketing</h1>
                                    <p>Ilmiya uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
                                    <p>Google Ads (AdWords): Google Ads (AdWords) remarketing service is provided by Google Inc.    </p>
                                    <ul>
                                        <li>
                                            <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <span>http://www.google.com/settings/ads.</span></p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on – <span>https://tools.google.com/dlpage/gaoptout</span> – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <span>https://policies.google.com/privacy?hl=en.</span></p>
                                        </li>
                                    </ul>
                                    <p> Twitter: Twitter remarketing service is provided by Twitter Inc.</p>
                                    <ul>
                                        <li>
                                            <p>You can opt-out from Twitter's interest-based ads by following their instructions: <span>https://support.twitter.com/articles/20170405.</span></p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <span>https://twitter.com/privacy.</span></p>
                                        </li>
                                    </ul>
                                    <p>Facebook: Facebook remarketing service is provided by Facebook Inc.</p>
                                    <ul>
                                        <li>
                                            <p>You can learn more about interest-based advertising from Facebook by visiting this page: <span> https://www.facebook.com/help/164968693837950.</span></p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <span>http://www.aboutads.info/choices/ </span> , the Digital Advertising Alliance of Canada in Canada <span>http://youradchoices.ca/</span> or the European Interactive Digital Advertising Alliance in Europe <span>http://www.youronlinechoices.eu/ </span> or opt-out using your mobile device settings.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <span>https://www.facebook.com/privacy/explanation.</span></p>
                                        </li>
                                    </ul>
                                </div>


                                <h4>17. Payments</h4>
                                <div className="description">
                                    <p>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                                    <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                                    <p>The payment processors we work with are:</p>
                                    <p>PayPal or Braintree:</p>
                                </div>
                                <ul>
                                    <li>
                                        <span>Their Privacy Policy can be viewed at <p>https://www.paypal.com/webapps/mpp/ua/privacy-full</p></span>
                                    </li>
                                    <p>Apple Store In-App Payments:</p>
                                    <li>
                                        <span>
                                            Their Privacy Policy can be viewed at: https://www.apple.com/legal/privacy/en-ww/ and https://support.apple.com/en-us/HT203027
                                        </span>
                                    </li>
                                    <p>Google Play In-App Payments:</p>
                                    <li>
                                        <span>
                                            Their Privacy Policy can be viewed at: https://policies.google.com/privacy?hl=en&gl=us? and https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en
                                        </span>
                                    </li>
                                    <p>Stripe:</p>
                                    <li>
                                        <span>
                                            Their Privacy Policy can be viewed at: https://stripe.com/us/privace
                                        </span>
                                    </li>
                                    <p>Authorize.net:</p>
                                    <li>
                                        <span>Their Privacy Policy can be viewed at: https://www.authorize.net/about-us/privacy/</span>
                                    </li>
                                    <p>Plaid:</p>
                                    <li>
                                        <span>Their End User Privacy Policy, Cookie Policy and Privacy Statement can be viewed at https://plaid.com/legal/.</span>
                                    </li>
                                    <p>Square:</p>
                                    <li>
                                        <span>Their Privacy Policy can be viewed at:</span>
                                        <p>https://squareup.com/us/en/legal/general/privacy/.</p>
                                    </li>
                                    <p>WePay:</p>
                                    <li><span>Their Privacy Policy can be viewed at: https://go.wepay.com/privacy-policy/.</span></li>
                                </ul>
                            </div>



                            <div className="suspend-section">
                                <h4>18. Links to Other Sites</h4>
                            </div>
                            <div className="description">
                                <p>
                                    our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                                </p>
                                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                            </div>
                            <div className="trial-account">
                                <h4>19. Children's Privacy</h4>
                                <div className="description">
                                    <p>Protecting the privacy of Children is especially important to Ilmiya. For that reason, we created certain features designed to help protect personal information relating to Children. When a Child creates an account, we seek the consent of a parent or legal guardian for that account. When Ilmiya is used in an educational setting, we rely on the institution to provide the requisite consent, on behalf of the Parent, for Ilmiya to collect information from a user under the age of 13.</p>
                                    <p>Please review our Child Privacy Statement <span>(https://ilmiya.com/coppa)</span> to learn more:</p>
                                </div>
                                <ul>
                                    <li>
                                        <span>
                                            How Children can use and register for our Service
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            Who will collect information about children
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            How information is collected
                                        </span>
                                    </li>
                                    <li>
                                        <span>Information we collect directly</span>
                                    </li>
                                    <li>
                                        <span>Information we collect automatically</span>
                                    </li>
                                    <li>
                                        <span>How we use children’s information</span>
                                    </li>
                                    <li>
                                        <span>How we share children’s information</span>
                                    </li>
                                    <li><span>Your rights to review, delete, and control our use of children’s personal information</span></li>
                                    <li>Changes to child privacy statement</li>
                                </ul>
                            </div>
                            <div className="description">
                                <p>PLEASE NOTE: THIS DISCLOSURE DOES NOT APPLY TO THE DATA COLLECTION PRACTICES OF ANY THIRD-PARTY</p>
                            </div>

                            <div className="suspend-section">
                                <h4>20. Changes to This Privacy Policy</h4>
                            </div>
                            <div className="description">
                                <p>
                                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                                </p>
                                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
                                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                            </div>

                            <div className="suspend-section">
                                <h4>21. Contact Us</h4>
                            </div>
                            <div className="description">
                                <p>
                                    If you have any questions about this Privacy Policy, please contact us:
                                </p>
                                <p>By email: <span>support@ilmiya.com</span>.</p>
                            </div>





                        </div>
                    </div>

                </div>
            </div>
            <SecondaryFooter />

        </>
    );
}