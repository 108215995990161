import React from 'react'
import ilmiyalogo from '../images/icons/Ilmiya Logo.png';
import logo from '../images/icons/Group 1413371562.png';
import blacklogo from '../images/icons/black logo ilmiya.png';
import bluelogo from '../images/icons/blue logo imiya.svg';
import SecondaryHeader from '../components/SecondaryHeader';
import SecondaryFooter from '../components/SecondaryFooter';

const Brand = () => {
  return (
    <>
      <SecondaryHeader />
      <div className='brand-page-wrapper'>
        <div className='container'>
          <div className="row">
            <div className="col-md-6">
            <div className='brand-heading'>
            <h1>Brand <span>Assets</span> </h1>
          </div>
          <div className='brand-content'>
            <p>Focus on what you do best—changing students' lives. Go borderless!Expand your impact with Ilmiya’s global infrastructure, designed to help you reach.</p>
          </div>
            </div>
            <div className="col-md-5"></div>
          </div>
         
          <div className='brand-card-wrapper'>
              <div className="row">
                <div className='mb-3 mb-md-4 col-6'>
                  <div className="brand-card">
                    <img src={bluelogo} alt="logo" />

                  </div>
                </div>
                <div className='mb-3 mb-md-4 col-6'>
                  <div className='brand-card'>
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <div className='mb-3 mb-md-4 col-6'>
                    <div className='brand-card'>
                      <img src={ilmiyalogo} alt="logo" />
                    </div>

                </div>
                <div className='mb-3 mb-md-4 col-6'>

                  <div className='brand-card'>
                    <img src={blacklogo} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  )
}

export default Brand
