import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import logo from "../images/icons/logo.svg";
import errorIcon from "../images/icons/error-icon.svg";
import errorBlack from "../images/icons/error-black.svg"
export default function RouterPage() {
  return (
    <>
      <SecondaryHeader />
      <div className="router-page-wrapper">
        <div className="container">
          <div className="router-page-form">
            <div className="form-wrapper">
              <div className="form-logo">
                <img src={logo} alt="icon" />
              </div>
              <div className="form-title">
                <h1>Find Organization</h1>
              </div>
              <div className="form-input-section">
                <div className="input-section error">
                  <input type="text" placeholder="Tenant" className="error"/>
                  <div className="domain">
                    <span>.myilmiya.com</span>
                  </div>
                </div>
                <div className="submit-btn">
                  <button>Next</button>
                </div>
              </div>
            </div>
            <div className="error-section">
              <div className="icon">
                <img src={errorIcon} alt="icon" className="light-theme-img"/>
                <img src={errorBlack} alt="icon" className="dark-theme-img"/>
              </div>
              <p>
                We couldn’t find your Organization. If you can’t remember your
                Org URL, check your invite email or contact your admin.
              </p>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  );
}
