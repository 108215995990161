import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";

const PrivacyDisclouser = () => {
    return (
        <>

                <div className="legal-details-page-wrappper">
                    <SecondaryHeader />
                    {/* <LegalTopbar /> */}
                    <div className="page-breadcrumb">
                        <strong>
                            <Link to={"/legal"}> Legal Center</Link>
                            / Privacy Policy
                        </strong>
                    </div>
                    <div className="page-content-wrapper">
                        {/* <LegalSidebar /> */}
                        <div className="page-content">
                            <div className="container">
                                <div className="page-content-section">
                                    <div className="content-title">
                                        {/* <div className="icon">
                    <img src={ilmiyaIcon} alt="icon" />
                </div> */}
                                        <span>Privacy Disclosure for Children (COPPA) </span>
                                    </div>

                                    <div className='content-wrapper'>
                                        <div className='description'>
                                            <p>Ilmiya, Inc. (“Ilmiya”) values the privacy of children and of all of its users. This Child Privacy Statement is designed to comply with the Children’s Online Privacy Protection Act (“COPPA”) and contains important information about how we collect, use, and disclose the personal information we collect from or about children under thirteen (13) years old who use our website, (the “Site”) and the services provided on our Site (the “Services”).</p>

                                            <p>Ilmiya will handle children’s personal information as described in this Child Privacy Statement. Any personal information we collect about other users (e.g., teens and adults) will be treated in accordance with the Ilmiya Privacy Statement (“Privacy Statement”). The use of our Site and any dispute over privacy, is subject to this Child Privacy Statement, the Privacy Statement, and our Terms of Service, including its applicable limitations on damages and the resolution of disputes. Our Terms of Service and Privacy Statement are incorporated by reference into this Child Privacy Statement.</p>
                                        </div>

                                        <div className='description'>
                                            <h1>1. Who Will Collect Information About Children?</h1>
                                            <p>Ilmiya operates the Site and Services and will collect children’s personal information as described in this COPPA Policy and our <span>Privacy Policy</span>. We can be contacted at:</p>
                                            <p>Email: <span>support@ilmiya.com</span></p>
                                            <p>Even if Ilmiya Services are offered in conjunction with an outside partner, the partner will not receive any individual personal information from users of the Site, including children. Ilmiya may provide partners with aggregate or de-identified information about users.</p>
                                        </div>
                                        <div className='description'>
                                            <h1>2. How Information is Collected?</h1>
                                            <p>We may collect information about children directly from the child’s parent or guardian, as well as automatically through a child’s use of our Site and Services. We will not require a child to disclose more information than is reasonably necessary to use our Services.</p>
                                        </div>
                                        <div className='description'>
                                            <h1>3. Information We Collect Directly</h1>
                                            <p>From children under 13, we only use child’s name, and email address to provide the Services. We will not contact a child using his or her email address or username. Parents may ask us to stop collecting personal information from their child by emailing us at : <span>From children under 13, we only use child’s name, and email address to provide the Services. We will not contact a child using his or her email address or username. Parents may ask us to stop collecting personal information from their child by emailing us at :</span>, however, in such cases the child will not be able to utilize the Services. If a parent directs us to stop collecting and using a child’s personal information, we must disable his or her use of our Services to ensure that no information is collected.</p>
                                        </div>

                                        <div className='description'>
                                            <h1>4. Information We Collect Automatically</h1>
                                            <p>We may automatically collect the following information about a child’s use of our Site through cookies and other technologies: domain name; browser type and operating system; web pages viewed; links clicked; the length of time spent on our Site; Google Analytics statistics; the referring URL, or the webpage that led the child to our Site; language information; device name and model; operating system type, name, and version; and activities within our Site. We may also collect IP address, device identifier or a similar unique identifier from users of our Site, including children; we only use such identifiers to support the internal operations of our Site and we do not use such identifiers to collect information about the child outside of our Site.</p>
                                        </div>

                                        <div className='description'>
                                            <h1>5. How We Use Children’s Information</h1>
                                            <p>We use personal information collected from children for the following purposes:</p>
                                            <p>To provide our Services; and</p>
                                            <p>To respond to customer service and technical support issues and requests.</p>
                                            <p>We de-identify and/or aggregate the information we collect from children under 13 before we use it for any other purposes, as noted below.</p>
                                            <p>UNIQUE IDENTIFIERS: We only collect and use unique identifiers, such as IP addresses, as necessary to operate our Site or Services, including to maintain or analyze their functioning; perform network communications; authenticate users or personalize content; and protect the security or integrity of users and our Site and Services. We never use unique identifiers to track users across third-party apps or websites.</p>
                                            <p>AGGREGATE OR DE-IDENTIFIED INFORMATION: We may use aggregate or de-identified information about children for research, analysis, and similar purposes. When we do so, we strip out names, e-mail, contact information, and other personal identifiers. We may use aggregate or de-identified information for the following purposes:</p>
                                            <ul>
                                                <li>
                                                    <p>To better understand how users access and use our Site and Services;</p>
                                                </li>
                                            </ul>

                                            <ul>
                                                <li>
                                                    <p>To improve our Site and Services and respond to user desires and preferences; and</p>
                                                </li>
                                            </ul>

                                            <ul>
                                                <li>
                                                    <p>To conduct research or analysis, including research and analysis by third parties.</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='description'>
                                            <h1>6. How We Share Children’s Information</h1>
                                            <p>We do not sell children’s personal information, and a child may not make his or her personal information public through our Services. In general, we may disclose the personal information that we collect about children to provide our Services, to comply with the law, and to protect Ilmiya and other users of our Services. For example, we may share children’s personal information as follows:</p>
                                            <p>SERVICE PROVIDERS: We may disclose the information we collect from children to third-party vendors, service providers, contractors, or agents who perform functions on our behalf.</p>
                                            <p>BUSINESS TRANSFERS: If we are acquired by or merged with another company, if substantially all of our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the personal information we have collected from our users to the other company.</p>
                                            <p>IN RESPONSE TO LEGAL PROCESS: We also may disclose the personal information we collect in order to comply with the law, a judicial proceeding, court order, subpoena, or other legal process.</p>
                                            <p>O PROTECT US AND OTHERS: We also may disclose the personal information we collect where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our <span>Terms of Service</span> , Privacy Statement, or this Child Privacy Statement, or as evidence in litigation in which ILMIYA is involved. </p>
                                            <p>WITH PARENTS: Parents may request information about the information we have collected from their child by contacting us at: <span>support@ilmiya.com </span>.</p>
                                            <p>AGGREGATE AND DE-IDENTIFIED INFORMATION: We may also use and share aggregate or de-identified information about users with third parties for marketing, research, or similar purposes.</p>
                                        </div>

                                        <div className='description'>
                                            <h1>7. Your Rights to Review, Delete, and Control Our Use of Children’s Personal Information</h1>
                                            <p>Parents have a right to review the information we have collected about their children and students, respectively, and to delete it, and to tell us to stop using it. To exercise these rights, you may contact us at : <span>Suppport @ilmiya.com</span>. You will be required to authenticate yourself as the child’s parent to receive information about that child. Please note that copies of information may remain in cached or archived form on our systems after you request us to delete it.</p>
                                        </div>
                                        <div className='description'>
                                            <h1>8. Changes to Child Privacy Statement</h1>
                                            <p>We may change this Child Privacy Statement from time to time, so please be sure to check back periodically. We will post any changes to this Child Privacy Policy on our Site. If we make any changes to this Child Privacy Statement that materially affect our practices with regard to the personal information we have previously collected from a child, we will endeavor to provide you with notice in advance of such change by highlighting the change on our Site.</p>
                                        </div>






                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SecondaryFooter />

        </>
    )
}

export default PrivacyDisclouser
