import { useState, useEffect } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import {Link} from "react-router-dom";
import docs from "../images/icons/docs.svg";
import signals from "../images/icons/signals.png";
import policy from "../images/icons/policy.png";
import Ilm from "../images/icons/ilm.svg";
import profileAvatar from "../images/icons/profile-avatar.svg";
import logo from "../images/icons/logo.svg"
import apps from "../images/icons/apps.svg"
import bars from "../images/icons/bar.svg"
import darkMode from "../images/icons/dark_mode.svg"
import newsRoom from "../images/icons/newsroom.svg"
import statusIcon from "../images/icons/status-icon.svg"
import lightMode from "../images/icons/light-mode.svg"

export default function HeaderComponent() {
  const [showMenu, setShowMenu] = useState(false);
  const [state, setState] = useState("");
  const [isDarkTheme, setIsDarkTheme] = useState(false);


  const darkThemeHandler = () => {
    // Toggle the state
    setIsDarkTheme(prevState => !prevState);
  };

  useEffect(() => {
    // Toggle the 'active' class on the body
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [isDarkTheme]); // Re-run the effect when isActive changes

  const showMenuHandler = () => {
    setShowMenu(true);
  };
  const closeMenuHandler = () => {
    setShowMenu(false);
  };
  const handleTheme = () => {
    if (state === "dark") {
      setState("light");
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    } else {
      document.body.classList.add("dark-theme");
      setState("dark");
      localStorage.setItem("theme", "dark");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.add("dark-theme");
      setState("dark");
    }
  }, []);

  return (
    <header className="header">
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-lg-2 col-2 align-self-center">
            <div className="logo-wrapper">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" />{" "}
              </Link>
              <a to="#" className="hamburger">
                <img
                  src={require("../images/icons/hamburger-icon.svg")}
                  alt="hamburgerIcon"
                  onClick={showMenuHandler}
                />
              </a>
            </div>
          </div>
          <div className="col-md-10 col-10 align-self-center">
            <div className={`menu-wrapper`}>
              {/* <RxCross2
                className="menu-close-icon"
                onClick={closeMenuHandler}
              /> */}
              <ul className="menu">
                <li className='borderd bordered'>
                <div className="header-dropdown">
                    <div className="brand-dropdown-wrapper">
                    <Dropdown className="brand-dropdown">
                        <Dropdown.Toggle className="p-0"> 
                        <img src={apps} alt="apps" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="logo-dropdown">
                          <Dropdown.Item href="https://support.ilmiya.com/">
                            <div className="single-item">
                              <div className="icon">  
                                <img src={docs} alt="contract icon" />
                              </div>
                              <a>
                                Support
                              </a>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item href="https://blog.ilmiya.com/">
                            <div className="single-item">
                              <div className="icon">
                                <img src={newsRoom} alt="contract icon" />
                              </div>
                              <a>
                                Newsroom
                              </a>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item href="/legal">
                            <div className="single-item">
                              <div className="icon">
                                <img src={policy} alt="contract icon" />
                              </div>
                              <a>
                                Legal Center
                              </a>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item href="/contact-us">
                            <div className="single-item">
                              <div className="icon">
                              <img src={statusIcon} alt="contract icon" className="no-filter" />
                              </div>
                              <a>
                          Contact Us
                        </a>
                            </div>
                          </Dropdown.Item>

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </li>
                <li className=''>
                  <img src={bars} alt="bar" />
                </li>
                <li className='borderd bordered '>
                <img
                    onClick={handleTheme}
                    src={darkMode}
                    alt="dark"
                    className='light-theme-img'
                  />
                  <img
                    onClick={handleTheme}
                    src={lightMode}
                    alt="dark"
                    className='dark-theme-img'
                  />
                </li>
                <li>
                  <img src={Ilm} alt="icon" />
                </li>
                <li>
                    <div className="profile-avatar">
                    <img src={profileAvatar} alt="icon"/>
                    <div className="online-dot">
                    </div>
                  

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
