import React from "react";
import person1 from "../images/person1.png";
import person2 from "../images/person2.png";
import person3 from "../images/person3.png";
import person4 from "../images/person4.png";
import person5 from "../images/Ellipse 4524.png";
import person6 from "../images/Ellipse 4525.png";
import person7 from "../images/Ellipse 4526.png";
import line from "../images/Line 543.png";
import plus from "../images/plus.png";
import group from "../images/Group 1261156389.png";
import message from "../images/Group 1261156385.png";
import vector1 from "../images/Vector 7010.png";
import vector2 from "../images/Vector 7011.png";
import vector3 from "../images/Vector.png";
import frame from "../images/Frame 1413371527.png";

function Management() {
  return (
    <div className="management-container">
      <div className="container">
      <p className="management-title">Beb SaaS Suite</p>
      <h2 className="management-header">The easy solution to multi-tenancy</h2>
      <p className="management-desc">
        It is a long established fact that a reader will be distracted by <br />{" "}
        the readable content of a page when looking at its layout. The
      </p>

      <div className="management-bottom-section">
      <div className="row">
        <div className="col-lg-4 col-md-6 ">
          <div className="custom-section management-border">
            <div className="custom-section-heading">
              <h2 className="management-title">Custom roles and permissions</h2>
              <p className="management-desc">
                Powerful primitives to fully customer your app’s authorization
                story.
              </p>
              <div className="custom-section-img">
                <div className="custom-img-sections">
                  <div className="img-section"></div>
                  <div className="img-section">
                    <img src={person1} alt="" />
                  </div>
                  <div className="img-section img-bg-color"></div>
                </div>
                <div className="custom-img-sections">
                  <div className="img-section">
                    <img src={person2} alt="" />
                  </div>
                  <div className="img-section"></div>
                  <div className="img-section">
                    <img src={person3} alt="" />
                  </div>
                </div>
                <div className="custom-img-sections">
                  <div className="img-section"></div>
                  <div className="img-section">
                    <img src={person4} alt="" />
                  </div>
                  <div className="img-section"></div>
                </div>
              </div>
            </div>
            {/* bottom scrollbar */}
            <div>
                <ul className="custom-section-bottom-list">
                    <li className="management-desc ">QA Tester</li>
                    <li className="management-desc">Owner</li>
                    <li className="management-desc management-list-outer">Engineer</li>
                    <li className="management-desc">Owner</li>
                </ul>
            </div>
          </div>
        </div>

        {/* second col */}
        <div className="col-lg-4 col-md-6">
          <div className="classes-section management-border">
            <div className="classes-img-section">
              <div className="classes-img">
                <img src={person5} alt="person" />
                <img src={person6} alt="person" />
                <img src={person7} alt="person" />
              </div>
              <img src={line} alt="line" />
              <img src={group} alt="arrow" className="classes-arrow" />
              <button className="classes-btn">
                <img src={plus} alt="plus" />
                Auto-join
              </button>
            </div>

            <div className="classes-section-heading">
              <h2 className="management-title">Classes</h2>
              <p className="management-desc">
                Powerful primitives to fully customer your app’s authorization
                story.{" "}
              </p>
            </div>
          </div>

          <div className="invitation-section management-border">
            <div className="invitation-top">
              <img src={vector2} alt="vector1" />
              <button className="invitation-btn">
                <img src={message} alt="message" />
                Invite this person
              </button>
              <img src={vector1} alt="vector2" className="invitation-arrow" />
            </div>

            <div className="invitation-heading">
              <h2 className="management-title">Invitations</h2>
              <p className="management-desc">
                Powerful primitives to fully customer your app’s authorization
                story.{" "}
              </p>
            </div>
          </div>
        </div>

        {/* third col */}
        <div className="col-lg-4 col-md-6">
          <div className="organizational-section management-border">
            <h2 className="management-title">Organizational Management</h2>
            <p className="management-desc">
              Powerful primitives to fully customer your app’s authorization
              story.
            </p>

            <div className="organizational-btn-section">
            <button className="organizational-btn">
              Clark
              <img src={vector3} alt="vector" />
            </button>
            </div>

            <img src={frame} alt="frame" />
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Management;
