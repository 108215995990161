import { Link } from 'react-router-dom'
import systemIcon from "../images/icons/systemicon.png"
export default function FooterBottomBar() {
  return (
    <>
      <div className="footer-bottom-bar">
        <div className="large1">
          <div className="row">
          <div className="col-md-7 col-lg-8  ">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={systemIcon}
                    alt="logo"
                    className="logo"
                  />
                </Link>
                <ul className="bottom-menu">
                  <li>
                    <Link to="https://status.ilmiya.com/" target='_blank'>Status</Link>
                  </li>
                  <li>
                    <Link to="https://legal.ilmiya.com/p/KdXuVooqb8Z1BE/Terms-of-Service">Terms</Link>
                  </li>
                  <li>
                    <Link to="https://legal.ilmiya.com/p/Q_NY3B88JWXzpS/Privacy-Policy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-lg-4  ">
              <div className="text-wrapper text-end">
                <p>© 2024 copyright ilmiya.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
