import React, { useState } from "react";
import { Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import aboutNewShape from "../images/about-new-shape.svg";
import aiImage from "../images/aiImage.png";
// import addCircle from "../images/add_circle.svg";
import aboutNewCalender from "../images/about-new-calender.svg";
import aboutStar from "../images/about-new-star.svg";
import buildIcon from "../images/build-icon.svg";
import buildSpaceIcon from "../images/build-space-icon.svg";
import addCircle from "../images/add_circle.svg";
import cloudCard from "../images/cloud-card.svg";
import SecondaryFooter from "../components/SecondaryFooter";
import bildilmiyaicon from "../images/ilmiya-site-icon.svg";
import modalImg2 from "../images/modalImg2.png";
import modalImg1 from "../images/modalImg1.png";
import modalImg3 from "../images/modalImg3.png";
import secureIcon from "../images/icons/secureIcon.svg";
import globalIcon from "../images/icons/globalIcon.svg";
import programmaticIcon from "../images/icons/programmaticIcon.svg";
import datadrivenIcon from "../images/icons/datadrivenIcon.svg";
import collaborationIcon from "../images/icons/collaborationIcon.svg";
import digitalcampusIcon from "../images/icons/digitalcampusIcon.svg";
import Aide from "../images/icons/Aide.svg";
import realtimeIcon from "../images/icons/realtimeIcon.svg";
import customizableIcon from "../images/icons/customizableIcon.svg";
import modalHeaderImg1 from "../images/icons/modalheaderimg1.svg";
import modalHeaderImg3 from "../images/icons/modalheaderimg3.svg";
import modalHeaderImg2 from "../images/icons/modalHeaderImg.svg";
import Engageicon from "../images/icons/Engageicon.svg";
import Catalogicon from "../images/icons/Catalogicon.svg";
import Teachicon from "../images/icons/Teachicon.svg";
import Playicon from "../images/icons/Playicon.svg";
import Createicon from "../images/icons/Createicon.svg";
import Discovericon from "../images/icons/Discovericon.svg";

import useraccessmng from "../images/useraccessmng.png";
import engagepngImage from "../images/engagepngImage.png";
import graphicelement from "../images/graphicelement.png";
import digitalimg1 from "../images/digitalimg1.png";
import commentsimage from "../images/commentsimage.png";
import cmsdraftimage from "../images/cmsdraftimage.png";
import accessibilityimage from "../images/accessibilityimage.png";
import shareimage from "../images/shareimage.png";
import futureimage from "../images/futureimage.png";
import layoutimage from "../images/layoutimage.png";
import administrationimg1 from "../images/administrationimg1.png";
import administrationimg2 from "../images/administrationimg2.png";
import administrationimg3 from "../images/administrationimg3.png";
import administrationimg4 from "../images/administrationimg4.png";
import ZeroHassleModal from "../components/ZeroHassleModal";
import CustomBuiltModal from "../components/CustomBuiltModal";
import EmbeddedAiModal from "../components/EmbeddedAiModal";
import CollaBModal from "../components/CollabModal";
import SpacesModal from "../components/SpacesModal";
import BuildModal from "../components/BuildModal";

function AboutNew() {
  const [activeImage, setActiveImage] = useState("People Management");
  const [activeModal, setActiveModal] = useState(null);
  const [modalData, setModalData] = useState({});

  const [showCollabModal, setShowCollabModal] = useState(false); 
  const [showSpacesModal, setShowSpacesModal] = useState(false); 
  const [showBuildModal, setShowBuildModal] = useState(false); 


  const handleCloseCollabModal = () => setShowCollabModal(false);
  const handleShowCollabModal = () => setShowCollabModal(true); 

  const handleCloseSpacesModal = () => setShowSpacesModal(false); 
  const handleShowSpacesModal = () => setShowSpacesModal(true);
  
  const handleCloseBuildModal = () => setShowBuildModal(false); 
  const handleShowBuildModal = () => setShowBuildModal(true);

  const handleShowModal = (modalType, data) => {
    setModalData(data);
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handleButtonClick = (image) => {
    setActiveImage(image);
  };


  return (
    <div className="aboutNew">
      <SecondaryHeader />
      <div className="container">
        <div className="learning-reimagined">
          <h2>
            Learning Reimagined. <br />
            The Educator Operating System
          </h2>
          <img src={aboutNewShape} alt="shape" />
        </div>
      </div>
      <div className="container" style={{ marginTop: '-550px' }}>
        <div className="Ai-image-container">
          <div className="main-img-wrapper">
            <div className="text-sec embeded-ai">
              <div className="comp1">
                <strong>Embedded AI</strong>
                <button
                  onClick={() =>
                    handleShowModal("EmbeddedAi", {
                      modalHeaderImg: modalHeaderImg1,
                      title: "AI That Redefines Learning",
                      image: modalImg1,
                      footer: {
                        footerimg1: Aide,
                        footertext1: "Personalized",
                        footerimg2: realtimeIcon,
                        footertext2: "Real Time",
                        footerimg3: customizableIcon,
                        footertext3: "Customizable",
                      },
                      // textData:
                      //   "Ilmiya’s AI provides real-time personalization, embedding intelligent insights directly into course materials. With secure, adaptive learning and intelligent data indexing, we deliver a deeply personalized,  educational experience.",
                    })
                  }
                >
                  <img
                    src={addCircle}
                    alt="addCircle"
                    className="add-circle-img"
                  />
                </button>
              </div>
              <span>
                Bring AI to campus at scale. Empower students and faculty with
                personalization.
              </span>
            </div>
            <div className="text-sec custom-built">
              <div className="comp1">
                <strong>Custom-built tools</strong>
                <button
                  onClick={() =>
                    handleShowModal("CustomBuilt", {
                      modalHeaderImg: modalHeaderImg2,
                      title: "Effortless, Powerful Hosting",
                      image: modalImg2,
                      footer: {
                        footerimg1: secureIcon,
                        footertext1: "Secure",
                        footerimg2: globalIcon,
                        footertext2: "Global",
                        footerimg3: programmaticIcon,
                        footertext3: "Programmatic",
                      },
                      // textData: "Ilmiya’s AI provides real-time personalization, embedding intelligent insights directly into course materials. With secure, adaptive learning and intelligent data indexing, we deliver a deeply personalized,  educational experience.",
                    })
                  }
                >
                  <img
                    src={addCircle}
                    alt="addCircle"
                    className="add-circle-img"
                  />  
                </button>
              </div>
              <span>
                Power a global education business. Everything you need in one
                place to make an impact and grow your profits.
              </span>
            </div>
            <div className="text-sec zero-hassle">
              <div className="comp1">
                <strong>Zero-hassle hosting</strong>
                <button
                  onClick={() =>
                    handleShowModal("ZeroHassle", {
                      modalHeaderImg: modalHeaderImg3,
                      title: "Intuitive Tools for Educators",
                      image: modalImg3,
                      footer: {
                        footerimg1: datadrivenIcon,
                        footertext1: "Data Driven",
                        footerimg2: collaborationIcon,
                        footertext2: "Collaborative",
                        footerimg3: digitalcampusIcon,
                        footertext3: "Digital Campus",
                      },
                      // textData:
                      //   "Applications Ilmiya offers a suite of essential education tools—SIS, administrative tools, reporting, and a Learning Experience Platform—all in one place. Manage and scale your programs effortlessly with intuitive, data-driven applications designed for educators.",
                    })
                  }
                >
                  <img
                    src={addCircle}
                    alt="addCircle"
                    className="add-circle-img"
                  />
                </button>
              </div>
              <span>
                Enjoy reliable, fast, and hassle-free hosting for your education
                business—managed entirely by Ilmiya.
              </span>
            </div>
            <div className="img-div">
              <img src={aiImage} alt="aiImage" className="ai-image-center" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="management">
          <div className="about-text">
            <h3>
              We’re on a mission to <br /> build the future of <br />
              education.
            </h3>
            <p>
              What we believe <br /> Principles that dictate how we think,
              behave, and make decisions.
            </p>
          </div>
          <div className="about-btn">
            <button
              onClick={() => setActiveImage("People Management")}
              className={`buttons ${activeImage === "People Management" ? "active" : ""}`}
            >
              People Management
            </button>
            <button
              onClick={() => setActiveImage("Engagement")}
              className={`buttons ${activeImage === "Engagement" ? "active" : ""}`}
            >
              Engagement
            </button>
            <button
              onClick={() => setActiveImage("Administration")}
              className={`buttons ${activeImage === "Administration" ? "active" : ""}`}
            >
              Administration
            </button>
            <button
              onClick={() => setActiveImage("Digital Campus")}
              className={`buttons ${activeImage === "Digital Campus" ? "active" : ""}`}
            >
              Digital Campus
            </button>
          </div>

          <div className="active-tabs-section">
            {activeImage === "People Management" && (
              <div className="gradient-border-bg">
                <div className="about-page-people-tabs-ui">
                  <div className="container">
                    <div className="sec1">
                      <div className="top-section">
                        <h1>
                          <span className="title-text1">
                            Simplify User Access
                          </span>{" "}
                          <span className="title-text2"> Management</span>
                        </h1>
                        <p>
                          Effortlessly manage student data, enhance academic
                          insights, and empower educators and families in one
                          seamless, intuitive platform.
                        </p>
                        <div className="request-demo-btn">
                          <Link
                            to="/contact-us"
                            className="btn-style text"
                            target="_blank"
                          >
                            Request demo
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="sec2">
                      <img src={useraccessmng} alt="bottom img" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeImage === "Engagement" && (
              <div className="gradient-border-bg">
                <div className="about-page-engage-tabs-ui">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Engageicon} alt="Engageicon" />
                            <strong>Engage</strong>
                          </div>
                          <p>
                            <strong>
                              Seamless Interaction, Unmatched Connectivity.
                            </strong>
                            <span>
                              {" "}
                              Build a vibrant learning community with tools
                              designed to keep everyone connected and engaged
                              effortlessly.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4 mb-4">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Catalogicon} alt="Catalogicon" />
                            <strong>Collaborate</strong>
                          </div>
                          <p>
                            <strong>Unite Minds, Amplify Impact.</strong>
                            <span>
                              {" "}
                              Foster a collaborative environment where
                              educators, students, and families come together to
                              create meaningful learning experiences.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-4 align-self-center">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Teachicon} alt="Teachicon" />
                            <strong>Teach</strong>
                          </div>
                          <p>
                            <strong>
                              Empower Education, Inspire Excellence.
                            </strong>
                            <span>
                              . Equip educators with streamlined tools that make
                              teaching intuitive, impactful, and effective.
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4 align-self-center">
                        <div className="img-wrapper">
                          <img src={engagepngImage} alt="engagepngImage" />
                        </div>
                      </div>
                      <div className="col-md-4 mb-4 align-self-center">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Playicon} alt="Playicon" />
                            <strong>Play</strong>
                          </div>
                          <p>
                            <strong>Unlock Joyful Learning Experiences.</strong>
                            <span>
                              {" "}
                              Engage learners through playful, interactive tools
                              that make education exciting and memorable
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Createicon} alt="Engageicon" />
                            <strong>Create</strong>
                          </div>
                          <p>
                            <strong>Bring Ideas to Life.</strong>
                            <span>
                              {" "}
                              Empower users to design, develop, and customize
                              learning experiences that resonate and inspire.
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4 mb-4">
                        <div className="text-card">
                          <div className="text-comp1">
                            <img src={Discovericon} alt="Discovericon" />
                            <strong>Discover</strong>
                          </div>
                          <p>
                            <strong>
                              Discover Unleash Curiosity, Drive Exploration.
                            </strong>
                            <span>
                              {" "}
                              Open up a world of possibilities with personalized
                              learning pathways that encourage exploration and
                              growth.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeImage === "Administration" && (
              <div className="gradient-border-bg">
                <div className="about-page-adminis-tabs-ui">
                  <div className="container">
                    <div className="top-img-container">
                      <img
                        src={administrationimg1}
                        alt="administrationimg1"
                        className="administrationimg1"
                      />
                      <img
                        src={administrationimg2}
                        alt="administrationimg2"
                        className="administrationimg2"
                      />
                    </div>
                    <div className="inner-content-administration-tab">
                      <img src={graphicelement} alt="graphicelement" />
                      <h1>
                        <span className="title-text1">
                          Empower admins to manage, monitor, and
                        </span>{" "}
                        <span className="title-text2"> maximize impact.</span>
                      </h1>
                      <p>
                        A unified, secure, and intuitive platform designed to
                        support educational institutions—managed entirely by
                        Ilmiya.
                      </p>
                      <div className="request-demo-btn">
                        <Link
                          to="/contact-us"
                          className="btn-style text"
                          target="_blank"
                        >
                          Request demo
                        </Link>
                      </div>
                    </div>
                    <div className="bottom-img-container">
                      <img
                        src={administrationimg3}
                        alt="administrationimg3"
                        className="administrationimg3"
                      />
                      <img
                        src={administrationimg4}
                        alt="administrationimg4"
                        className="administrationimg4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeImage === "Digital Campus" && (
              <div className="gradient-border-bg">
                <div className="about-page-digital-tabs-ui">
                  {/* <div className="container"> */}
                  <div className="background-boxes-image">
                    <div className="digital-tab-relative-imgs">
                      <img
                        src={commentsimage}
                        alt="commentsimage"
                        className="comment-img"
                      />
                      <img
                        src={cmsdraftimage}
                        alt="cmsdraftimage"
                        className="cmsdraft-img"
                      />
                      <img
                        src={accessibilityimage}
                        alt="accessibilityimage"
                        className="accessibility-img"
                      />
                      <img
                        src={shareimage}
                        alt="shareimage"
                        className="share-img"
                      />
                      <img
                        src={digitalimg1}
                        alt="digitalimg1"
                        className="digital-img"
                      />
                      <img
                        src={futureimage}
                        alt="futureimage"
                        className="future-img"
                      />
                      <img
                        src={layoutimage}
                        alt="layoutimage"
                        className="layout-img1"
                      />
                      <img
                        src={layoutimage}
                        alt="layoutimage"
                        className="layout-img2"
                      />
                    </div>

                    <div className="sec1">
                      <div className="top-section">
                        <h1>
                          <span className="title-text1">Think, plan,</span>{" "}
                          <span className="title-text2">all in one place</span>
                        </h1>
                        <p>
                          Enjoy reliable, fast, and hassle-free hosting for your
                          education business—managed entirely by Ilmiya.
                        </p>
                        <div className="request-demo-btn">
                          <Link
                            to="/contact-us"
                            className="btn-style text"
                            target="_blank"
                          >
                            Request demo
                          </Link>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="text-box">
          <h6>Planet Scale or Web Scale</h6>
          <h3>
            Our features you love.
            <br /> And even <span>More to discover</span>
          </h3>
          <p>
            Learning Nexus is a dynamic platform designed to revolutionize the
            way individuals engage with learning. With a comprehensive array of
            interactive courses. Learning Nexus is a dynamic platform.
          </p>
        </div>
      </div>
      <div class="container">
        <div className="cards-box ">
          <div class="row h-100">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div className="about-new-card">
                <div className="star-text">
                  <img src={aboutStar} />
                  <h6>Collab</h6>
                </div>
                <p>
                  Learning Nexus is a dynamic platform designed to revolutionize
                  the way engage.
                </p>
                <button className="circle-img" onClick={handleShowCollabModal}>
                  <img src={addCircle} alt="addCircle" />
                </button>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div className="about-new-card" style={{ paddingTop: "80px" }}>
                <div className="star-text">
                  <img src={buildSpaceIcon} />
                  <h6>Spaces</h6>
                </div>
                <p>
                  Learning Nexus is a dynamic platform designed to revolutionize
                  the way engage.
                </p>
                <button className="circle-img" onClick={handleShowSpacesModal}>
                  <img src={addCircle} alt="addCircle" />
                </button>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div className="about-new-card">
                <div className="star-text">
                  <img src={buildIcon} />
                  <h6>Build</h6>
                </div>
                <p>
                  Learning Nexus is a dynamic platform designed to revolutionize
                  the way engage.
                </p>
                <button className="circle-img" onClick={handleShowBuildModal}>
                  <img src={addCircle} alt="addCircle" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="cloud-platform">
          <div className="cloud-text">
            <h6>Ilmiya Cloud Platfrom</h6>
            <h1>Pack A Punch</h1>
            <p>
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage with learning. With a comprehensive array
              of interactive courses. Learning Nexus is a dynamic platform.
            </p>
          </div>

          <div className="cloud-card">
            <img src={cloudCard} alt="" />
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="build-box">
          <div className="build-content">
            <div className="build-text">
              <h6>Partner with Ilmiya</h6>
              <h1>Let’s build. Together</h1>
            </div>
            <div className="ilmiya-img">
              <img src={bildilmiyaicon} alt="" />
            </div>
            <div className="build-btn">
              <button className="build-btn1">Getting Start</button>
              <button className="build-btn2">Become a partner</button>
            </div>
          </div>
        </div>
      </div>
      <EmbeddedAiModal
        isOpen={activeModal === "EmbeddedAi"}
        onClose={handleCloseModal}
        data={modalData}
      />
      <CustomBuiltModal
        isOpen={activeModal === "CustomBuilt"}
        onClose={handleCloseModal}
        data={modalData}
      />
      <ZeroHassleModal
        isOpen={activeModal === "ZeroHassle"}
        onClose={handleCloseModal}
        data={modalData}  
      />
      <CollaBModal show={showCollabModal} handleClose={handleCloseCollabModal} />

      <SpacesModal show={showSpacesModal} handleClose={handleCloseSpacesModal} />
      
      <BuildModal show={showBuildModal} handleClose={handleCloseBuildModal} />
      
      <SecondaryFooter />
      
    </div>
  );
}

export default AboutNew;
