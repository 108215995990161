import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import spacesModalHeaderIcon from "../images/icons/spacesModalHeaderIcon.svg";
import filtericon from "../images/icons/filtericon.svg"
import spacepersonImg1 from "../images/spacepersonImg1.png"
import spacepersonImg2 from "../images/spacepersonImg2.png"
import spacepersonImg3 from "../images/spacepersonImg3.png"
import spacepersonImg4 from "../images/spacepersonImg4.png"

function SpacesModal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} centered onHide={handleClose} className="collab-modal spaces-modal-diff">
        <Modal.Body>
          <div className="head-section text-center">
            <img src={spacesModalHeaderIcon} alt="Header Icon" />
            <div className="text-data-section">
              <h2>Spaces</h2>
              <p>
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
          </div>
          <div className="container">
          <div class="table-container">
          <div class="input-container">
  <div class="input-wrapper">
    <span class="input-icon">
        <img src={filtericon} alt="filtericon" />
    </span>
    <input type="text" placeholder="Filter students" class="filter-input" />
  </div>
</div>
<div className="table-wrapper">
<table class="custom-table">
              <thead>
                <tr>
                  <th class="start">
                    <input type="checkbox" class="header-checkbox" />
                    <span className="th-text"> Name</span>
                  </th>
                  <th class="end">
                    <span className="th-text">Grade</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="first-td">
                    <input type="checkbox" class="row-checkbox" />
                    <img src={spacepersonImg1} alt="Avatar" class="avatar" />
                    <span className="name-column">Marvin McKinney</span>
                    </div>
                  </td>
                  <td>
                    <span className="grade-column">2nd</span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <input type="checkbox" class="row-checkbox" />
                    <img src={spacepersonImg2} alt="Avatar" class="avatar" />
                    <span className="name-column">Jenny Wilson</span>
                    </div>
                  </td>
                  <td>
                    <span className="grade-column">4th</span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <input type="checkbox" class="row-checkbox" />
                    <img src={spacepersonImg3} alt="Avatar" class="avatar" />
                    <span className="name-column">Jane Cooper</span>
                    </div>
                  </td>
                  <td>
                    <span className="grade-column">5th</span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <input type="checkbox" class="row-checkbox" />
                    <img src={spacepersonImg4} alt="Avatar" class="avatar" />
                    <span className="name-column">Jane Cooper</span>
                    </div>
                  </td>
                  <td>
                    <span className="grade-column">5th</span>
                  </td>
                </tr>
                <tr>
                  <td>
                  <div className="first-td">
                    <input type="checkbox" class="row-checkbox" />
                    <img src={spacepersonImg4} alt="Avatar" class="avatar" />
                    <span className="name-column">Jane Cooper</span>
                    </div>
                  </td>
                  <td>
                    <span className="grade-column">5th</span>
                  </td>
                </tr>
              </tbody>
            </table>
</div>
       
          </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SpacesModal;
