import React from "react";
import graphicElement from "../images/Graphic Elements.png";
import Frame from "../images/frame2.png";

function MainSection() {
  return (
   <div className="main-container ">
     <div className="container">
      <div className="main-img-section">
        <img
          src={graphicElement}
          alt="graphicElement"
          className="main-section-img"
        />
      </div>
      <p className="main-title main-heading">Advanced Security</p>
      <div>
        <h2 className="main-header">It all starts with the user</h2>
        <p className="main-desc">
          Learning Nexus is a dynamic platform designed to revolutionize the way
          <br />
          individuals engage. Nexus is a dynamic platform designed to
          revolutionize
          <br /> the way individuals engage.
        </p>
      </div>

      <div className="main-database-section">
        <div className="row gy-2">
          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text">Database changes</h2>
            </div>
            <p className="database-desc  header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text">Database changes</h2>
            </div>
            <p className="database-desc  header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text">Database changes</h2>
            </div>
            <p className="database-desc header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text">Database changes</h2>
            </div>
            <p className="database-desc header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text">Database changes</h2>
            </div>
            <p className="database-desc header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="database-top">
              <img src={Frame} alt="frame" className="database-img" />
              <h2 className="database-header header-text ">Database changes</h2>
            </div>
            <p className="database-desc header-desc">
              Learning Nexus is a dynamic platform designed to revolutionize the
              way individuals engage. Nexus is a dynamic platform designed to
              revolutionize the way individuals engage.
            </p>
          </div>
        </div>
      </div>
    </div>
   </div>
  );
}

export default MainSection;
