import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import collabModalHeaderIcon from "../images/icons/collabModalHeaderIcon.svg";
import collabImg1 from "../images/collabImg1.png";
import collabImg2 from "../images/collabImg2.png";
import collabImg3 from "../images/collabImg3.png";
import collabImg4 from "../images/collabImg4.png";
import collabImg5 from "../images/collabImg5.png";
import collabImg6 from "../images/collabImg6.png";

function CollaBModal({ show, handleClose }) {
  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="collab-modal">
        {/* <Modal.Header>
        </Modal.Header> */}
        <Modal.Body>
          <div className="head-section text-center">
            <img src={collabModalHeaderIcon} alt="Header Icon" />
            <div className="text-data-section">
              <h2>Collab</h2>
              <p>
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
          </div>
          <div className="image-container">
            <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg1} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg2} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg3} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg4} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg5} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="img-card">
                <img src={collabImg6} alt="collab img" />
                <span>Floyd Miles</span>
              </div>
            </div>
          </div>
          </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose}>Save Changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default CollaBModal;
