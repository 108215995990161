import React from "react";
import left from "../images/Rectangle 34624506.png";
import right from "../images/Rectangle 34624507.png";
import draft from "../images/drafts.png";
import google from "../images/Group 4.png";
import apple from "../images/apple-fill.png";
import microsoft from "../images/Microsoft_logo 1.png";
import arrow from "../images/arrow_right_alt.png";
import browseSection from "../images/browse_activity.png";
import cardMember from "../images/card_membership.png";
import school from "../images/icons/school.png";

function EngageSection() {
  return (
    <div className="engage-container">
      <div className="container">
      <p className="main-heading">Engage Platform</p>
      <h2 className="main-header">It all starts with the user</h2>
      <p className="main-desc">
        Learning Nexus is a dynamic platform designed to revolutionize the way
        <br />
        individuals engage. Nexus is a dynamic platform designed to
        revolutionize
        <br /> the way individuals engage
      </p>

      <div className="engage-space-section">
        <div className="engage-space-left-section">
          <div className="engage-left-top">
            <img src={left} alt="left" className="engage-left d-none d-md-block" />
            <div className="engage-form-section">
              <h2 className="engage-form-title">Create an ilmiya account</h2>
              <div className="engage-input">
                <img src={draft} alt="img" />
                <input type="email" placeholder="Sign up with email" />
              </div>
              <div className="engage-border">
                <hr class="line" />
                <p className="engage-form-desc">Or continue with</p>
                <hr class="line" />
              </div>

              <div className="engage-social-section">
                <div className="engage-social-img">
                  <img src={google} alt="google" />
                </div>
                <div className="engage-social-img">
                  <img src={apple} alt="google" />
                </div>
                <div className="engage-social-img">
                  <img src={microsoft} alt="google" />
                </div>
              </div>

              <p className="engage-login">
                Already have an ilmiya account?{" "}
                <a href="#">
                  Login <img src={arrow} alt="" />
                </a>
              </p>

              <p className="engage-policy">
                By creating an account you agree to the{" "}
                <a href="#">
                  Terms <br />
                  of Service
                </a>{" "}
                and <a href="#"> Privacy Policy.</a>
              </p>

              <div className="engage-list">
                <ul>
                  <li>
                    <a href="#">Service</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Terms</a>
                  </li>
                </ul>
              </div>
            </div>
            <img src={right} alt="right" className="engage-right d-none d-md-block" />
          </div>

          <div className="engage-left-bottom">
            <h2 className="engage-left-header">Assignments</h2>
            <p className="engage-left-desc">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution
            </p>
          </div>

          <div className="engage-left-bottom-img">
            <div className="engage-img-section">
            <div className="engage-left-img">
                <img src={school} alt="school" />
            </div>
            <div className="engage-left-img">
            <img src={browseSection} alt="browse" />
            </div>
            <div className="engage-left-img">
            <img src={cardMember} alt="card" />
            </div>
            </div>

            <div className="engage-img-section">
            <div className="engage-left-img">
                <img src={school} alt="school" />
            </div>
            <div className="engage-left-img">
            <img src={browseSection} alt="browse" />
            </div>
            <div className="engage-left-img">
            <img src={cardMember} alt="card" />
            </div>
            </div>

            <div className="engage-img-section">
            <div className="engage-left-img">
                <img src={school} alt="school" />
            </div>
            <div className="engage-left-img">
            <img src={browseSection} alt="browse" />
            </div>
            <div className="engage-left-img">
            <img src={cardMember} alt="card" />
            </div>
            </div>

          </div>
        </div>

        <div className="engage-space-right-section">
            <h2 className="engage-right-header">Spaces</h2>
            <p className="engage-right-desc">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default EngageSection;
