import React from 'react'
import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import { Link } from "react-router-dom";

const ParentalConsent = () => {
    return (
        <>
            <div className="legal-details-page-wrappper">
                <SecondaryHeader />
                {/* <LegalTopbar /> */}
                <div className="page-breadcrumb">
                    <strong>
                        <Link to={"/legal"}> Legal Center</Link>
                        / Privacy Policy
                    </strong>
                </div>
                <div className="page-content-wrapper">
                    {/* <LegalSidebar /> */}
                    <div className="page-content">
                        <div className="container">
                            <div className="page-content-section">
                                <div className="content-title">
                                    {/* <div className="icon">
                <img src={ilmiyaIcon} alt="icon" />
              </div> */}
                                    <span>Parental Consent</span>
                                </div>

                                <div className="content-wrapper">
                                    <div className='description'>
                                        <p>At Ilmiya, Inc., we are dedicated to transparency and clarity in how students use our services in the classroom. This comprehensive guide aims to help K–12 schools and teachers communicate effectively with parents about how Ilmiya's platform is used, and what protections are in place regarding student data and privacy.</p>
                                    </div>
                                    <div className='description'>
                                        <h1>Overview of School Use of Ilmiya</h1>
                                        <p>Schools vary in how they incorporate Ilmiya into their classroom experiences, as administrators and teachers determine which Ilmiya services will be utilized. Many schools are bound by data privacy laws such as the <span> Family Educational Rights and Privacy Act (FERPA)</span>in the U.S. and similar international privacy regulations like the <span>General Data Protection Regulation (GDPR)</span>. These laws govern how personal information is collected, stored, and shared. Ilmiya helps schools comply with these regulations by providing resources, privacy guidelines, and the necessary notices that schools can share with parents.</p>
                                    </div>

                                    <div className='description'>
                                        <h1>Obtaining Permissions for Student Use</h1>
                                        <p>Before students can use Ilmiya, teachers and school administrators must ensure they have the correct permissions from parents. Ilmiya follows global best practices in privacy law, including the <span>Children’s Online Privacy Protection Act (COPPA)</span> in the U.S., which mandates parental consent for the collection, use, and disclosure of personal information from children under 13.</p>
                                        <p>In international jurisdictions, Ilmiya acts as a <strong>data processor</strong> , meaning it processes student information on behalf of the school, which is the <strong>data controller</strong>. Schools are responsible for ensuring parental consent is obtained in line with local laws, and Ilmiya offers all required disclosures and guidance to help them do so. Regardless of location, Ilmiya applies high privacy standards, including those outlined in COPPA, to ensure data is handled responsibly and with the necessary consent.</p>
                                        <p>In the U.S., COPPA safeguards the online privacy of children under 13 by requiring schools or parents to provide consent before Ilmiya can collect, use, or disclose personal data. Schools and teachers can grant consent as the agent of the parent (known as school consent), provided they comply with Ilmiya’s requirements and send the necessary notices to parents. I</p>
                                    </div>

                                    <div className='description'>
                                        <h1>Key elements of COPPA compliance include:</h1>
                                        <ul>
                                            <li>
                                                <strong>School consent:<p>Allows teachers and schools to act on behalf of parents for educational purposes.</p></strong>
                                            </li>
                                        </ul>

                                        <ul>
                                            <li>
                                                <strong>Direct parental consent:<p>Required if parents set up their child’s account independently or if the child creates an account with a teacher’s supervision.</p></strong>
                                            </li>
                                        </ul>

                                        <p>Regardless of the method, all personal data collected through Ilmiya is used solely for educational purposes, and no student data is shared or sold to third parties for marketing.</p>
                                        <p>Obtaining parent permission is essential for students' use of the <strong>Ilmiya Platform</strong>, particularly for those under 13 years of age. In accordance with the <strong>U.S. Federal Trade Commission’s (FTC) Children’s Online Privacy Protection Act (COPPA)</strong> , Ilmiya ensures compliance by requiring consent or parental notice before collecting, using, or disclosing any personal information of children. This policy not only applies within the U.S., but Ilmiya extends these protections globally to ensure consistency and transparency in all jurisdictions where the platform is used.</p>

                                    </div>

                                    <div className='description'>
                                        <h1>Template for Gaining Parental Consent</h1>
                                        <p>Teachers play a critical role in communicating with parents about student engagement on the Ilmiya Platform. The following is a suggested communication template for gaining parental consent:</p>
                                        <strong>Permission Needed for Your Child’s Student Account</strong>
                                        <p>Yes, I give permission for you to create a student account for my child and allow my child to use the Ilmiya Platform in the classroom. I also consent to the collection, use, and disclosure of information about my child as outlined in the Ilmiya Platform’s Privacy Policy.</p>
                                        <strong>Dear Parents,</strong>
                                        <p>This year, we will be using the Ilmiya Platform to enhance learning and help document your child’s classroom experiences. With Ilmiya, your child will be able to create and share learning activities such as photos, videos, and notes in a secure digital portfolio. This portfolio is visible only to you, your child, and myself, and I will review all submissions before they are shared. Additionally, I will be using Ilmiya to send important updates, announcements, and messages to keep you informed about your child’s progress. If your child is under 13 years of age, I need your permission to create a <strong>Student Account</strong> for them. Kindly sign the consent form below and return it as soon as possible. Our goal is to have full participation from all families!</p>
                                    </div>

                                    <div className='description'>
                                        <h1>Teacher Guidance: Ensuring Compliance</h1>
                                        <p>Teachers and school administrators should ensure that the use of the Ilmiya Platform complies with relevant laws, including <span>COPPA</span>, the <strong> Family Educational Rights and Privacy Act (FERPA)</strong>, and any regional laws, such as the <strong>General Data Protection Regulation (GDPR)</strong> in the European Union.</p>
                                        <p><strong> For U.S. Schools:</strong> Under <strong>FERPA</strong>, schools must ensure that any third-party educational tool complies with the institution’s privacy requirements. Ilmiya works with schools to ensure all data is processed strictly for educational purposes, and that appropriate safeguards are in placce.</p>
                                        <p><strong>For Schools Outside the U.S.:</strong>In regions where other data protection laws apply, such as the <strong>UK GDPR</strong> or  <strong>EU GDPR</strong>Ilmiya functions as a data processor on behalf of schools (data controllers). This means that schools are responsible for obtaining the necessary parental permissions required by local laws, while Ilmiya ensures compliance with those requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryFooter />

        </>
    )
}

export default ParentalConsent
