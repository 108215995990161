import SecondaryHeader from "../components/SecondaryHeader";
import ilmiyaIcon from "../images/icons/ilmiya-icon.svg";
import iPad from "../images/iPad.png";
import LegalTopbar from "../components/LegalTopbar";
import { Link } from "react-router-dom";
import LegalSidebar from "../components/LegalDetailSidebar";
import SecondaryFooter from "../components/SecondaryFooter";

export default function LegalDetailPage() {
  return (
    <div className="legal-details-page-wrappper">
      <SecondaryHeader />
      {/* <LegalTopbar /> */}
      <div className="page-breadcrumb">
        <strong>
          <Link to={"/legal"}> Legal Center</Link>
          / Terms Of Service
        </strong>
      </div>
      <div className="page-content-wrapper">
        {/* <LegalSidebar /> */}
        <div className="page-content">
          <div className="container">
          <div className="page-content-section">
            <div className="content-title">
              {/* <div className="icon">
                <img src={ilmiyaIcon} alt="icon" />
              </div> */}
              <span>Terms of Service</span>
            </div>
            <div className="content-wrapper">
              <div className="description">
                <p>
                  You can try out the Zendesk platform with a free, 14-day trial
                  account. If you're interested in becoming a Zendesk developer
                  partner, you can convert your trial account into a sponsored
                  Zendesk Support account.
                </p>
                <p>
                  As part of becoming a Zendesk developer partner, Zendesk
                  sponsors an instance for up to 5 agents that you can use for
                  developing, demoing, and troubleshooting your app or
                  integration.
                </p>
                <p>
                  Unlike a trial account, a sponsored account does not expire
                  after 14 days.
                </p>
                <p>
                  By default, all Marketplace partners with a sponsored Support
                  account receive the Suite Enterprise plan. A sponsored Sell
                  account includes all features on the Sell Elite plan except
                  the Firehouse, Sync, and Search APIs.
                </p>
                <p>
                  <span>Note: </span>Zendesk will suspend any sponsored Support
                  account used to provide actual support.
                </p>
              </div>
              <div className="trial-account">
                <h4>Getting a trial account</h4>
                <ul>
                  <li>
                    <span>
                      For a trial Support account,
                      see <Link to="#">https://www.zendesk.com/register/free-trial/</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      For a trial Sell account,
                      see <Link to={"#"}>https://www.zendesk.com/register/?source=zendesk_sell#step-1</Link>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="suspend-section">
                <h4>Requesting a sponsored test account</h4>
                <ol>
                  <li>
                    <span>
                      If not already done, sign up for a free Zendesk Support
                      trial at{" "}
                      <Link to="#">
                        https://www.zendesk.com/register/free-trial/
                      </Link>
                      , or Sell trial at{" "}
                      <Link to={"#"}>
                        https://www.zendesk.com/register/?source=zendesk_sell#step-1
                      </Link>
                      .
                    </span>
                  </li>
                  <li>
                    <span>
                      When you're setting up the account, make sure to add the
                      prefix "d3v-" to your chosen subdomain name.
                      Example: d3v-mondocam.zendesk.com.
                    </span>
                  </li>
                  <li>
                    <span>
                      If you already have a trial account, add the "d3v-" prefix
                      to your subdomain. See Renaming your subdomain for
                      instructions.
                    </span>
                  </li>
                  <li>
                    <span>Fill out the Sponsored Account Request Form</span>
                  </li>
                </ol>
              </div>
              {/* <div className="page-banner">
                <img src={iPad} alt="image" />
              </div> */}
              <div className="description">
                <p>
                  You can try out the Zendesk platform with a free, 14-day trial
                  account. If you're interested in becoming a Zendesk developer
                  partner, you can convert your trial account into a sponsored
                  Zendesk Support account.
                </p>
                <p>
                  As part of becoming a Zendesk developer partner, Zendesk
                  sponsors an instance for up to 5 agents that you can use for
                  developing, demoing, and troubleshooting your app or
                  integration.
                </p>
                <p>
                  Unlike a trial account, a sponsored account does not expire
                  after 14 days.
                </p>
                <p>
                  By default, all Marketplace partners with a sponsored Support
                  account receive the Suite Enterprise plan. A sponsored Sell
                  account includes all features on the Sell Elite plan except
                  the Firehouse, Sync, and Search APIs.
                </p>
                <p>
                  <span>Note: </span>Zendesk will suspend any sponsored Support
                  account used to provide actual support.
                </p>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
      <SecondaryFooter />
    </div>
  );
}
