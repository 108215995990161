import React, { useState } from "react";
import CollaBModal from "../components/CollabModal";
import SpacesModal from "../components/SpacesModal";
import BuildModal from "../components/BuildModal";

const AboutDemoPageSideModals = () => {


  return (
    <div>
      <button onClick={handleShowCollabModal}>Open Collab Modal</button>
      <CollaBModal show={showCollabModal} handleClose={handleCloseCollabModal} />

      <button onClick={handleShowSpacesModal}>Open Spaces Modal</button>
      <SpacesModal show={showSpacesModal} handleClose={handleCloseSpacesModal} />
      
      <button onClick={handleShowBuildModal}>Open Build Modal</button>
      <BuildModal show={showBuildModal} handleClose={handleCloseBuildModal} />
    </div>
  );
};

export default AboutDemoPageSideModals;
