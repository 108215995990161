import React from "react";
import frame1 from "../images/Frame 126.png";
import frame2 from "../images/Frame 12611.png";
import frame3 from "../images/icons/ai-usage.svg";
import frame4 from "../images/Frame 1261156.png";
import group1 from "../images/Group 1261156360.png";
import group2 from "../images/Group 1261156361.png";
import done from "../images/Done.png";
import group3 from "../images/Group 1261156356.png";
import group4 from "../images/Group 1261156530.png";

function AdminSection() {
  return (
   <div className="admin-container">
     <div className=" container">
      <p className="main-heading">Advanced Security</p>
      <h2 className="main-header">Admin</h2>
      <p className="main-desc">
        Learning Nexus is a dynamic platform designed to revolutionize the way
        <br />
        individuals engage. Nexus is a dynamic platform designed to
        revolutionize
        <br /> the way individuals engage.
      </p>

      <div className=" row">
        <div className="col-lg-3 col-md-6 mt-4">
          <div className="support-section">
            <div className="support-heading">
              <div className="support-img">
                <img src={frame1} alt="frame1" />
              </div>
              <h2 className="support-header analytic-header">SAML Support</h2>
              <p className="support-desc analytic-desc">
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
            <div className="alex-section">
              <div className="support-alex">
                <p className="support-alex-section1">alex12345</p>
                <p className="support-alex-section2">alex12345</p>
              </div>
              <div className="support-alex">
                <p className="support-alex-section1"></p>
                <p className="support-alex-section2"></p>
              </div>
              <div className="support-alex">
                <p className="support-alex-section1">alex12345</p>
                <p className="support-alex-section2">alex12345</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-6 mt-4">
          <div className="global-ai-section">
           <div className="global-left-section">
           <div className="global-heading-div">
              <img src={frame2} alt="frame2" />
              <h2 className="global-heading analytic-header">Global AI</h2>
              <p className="analytic-desc">
                Learning Nexus is a dynamic platform designed to revolutionize
                the way engage.
              </p>
            </div>
            <div className="ai-img">
              <img src={frame3} alt="frame3" />
              <p className="ai-title">Global AI</p>
            </div>
           </div>

            <div className="global-right-section">
              <img src={group2} alt="group2" />
              <img src={group1} alt="group1" className="global-right-img" />
            </div>
            {/* <div className="ai-img">
              <img src={frame3} alt="frame3" />
              <p className="ai-title">Global AI</p>
            </div> */}
          </div>
        </div>
      </div>

          <div className="admin-database-section">
            <div className="admin-database-left-section">
              <div className="admin-database-top">
                <img src={frame4} alt="" />
                <h2 className="admin-database-header analytic-header">Database</h2>
                <p className="analytic-desc">
                  Learning Nexus is a dynamic platform designed to revolutionize
                  the way engage.
                </p>
              </div>
              <div className="admin-database-bottom">
                <div className="admin-database-heading">
                  <img src={done} alt="done" />
                  <p>100% Protable</p>
                </div>
                <div className="admin-database-heading">
                  <img src={done} alt="done" />
                  <p>Built-in Auth with RLS</p>
                </div>
                <div className="admin-database-heading">
                  <img src={done} alt="done" />
                  <p>Easy to extend</p>
                </div>
              </div>
            </div>
            <div className="admin-database-right-section">
              <img src={group3} alt="group3" />
              <img src={group4} alt="group4" className="database-right-img" />
            </div>
        </div>
        
      <div className="admin-label-section">
        <div className="admin-label-img">
          <img src={frame1} alt="frame1" />
        </div>
        <h2 className="admin-label-header analytic-header">Labels</h2>
        <p className="admin-label-title analytic-desc">
          Learning Nexus is a dynamic platform designed to.
        </p>
      </div>
    </div>
   </div>
  );
}

export default AdminSection;
